import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { ProductRole } from '@auth/models/user.model';
import { OrgMemberModel } from '@common/models/org-mem.model';
import { Page } from '@common/models/page.model';
import { OrgMemberService } from '@common/services/org-member.service';
import { UrlUtilService } from '@common/services/url-util.service';
import { FeatureType, OrgModel } from '@main/org/models/org.model';
import { OrgMemberships } from '@main/store/org-membership/org-membership.actions';
import { User } from '@main/store/user/user.actions';
import { UserSelectors } from '@main/store/user/user.selectors';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

export class OrgMembershipStateModel {
  orgMemberships?: OrgMemberModel[]; // Assigned on app load
}

const orgMembershipStateModelDefault: OrgMembershipStateModel = {};

@State<OrgMembershipStateModel>({ name: 'orgMembership', defaults: orgMembershipStateModelDefault })
@Injectable()
export class OrgMembershipState {
  constructor(
    private memberService: OrgMemberService,
    private store: Store,
    private http: HttpClient,
    private urlUtil: UrlUtilService
  ) {}

  @Action(OrgMemberships.Reset)
  resetOrgMembership(ctx: StateContext<OrgMembershipStateModel>) {
    ctx.setState(orgMembershipStateModelDefault);
  }

  @Action(User.GetSuccess)
  @Action(OrgMemberships.Get)
  getOrgMemberships() {
    return this.store.select(UserSelectors.user).pipe(
      filter((val) => !!val),
      take(1),
      switchMap((user) => {
        const isSysAdmin = this.store.selectSnapshot(UserSelectors.isSysAdmin);

        if (!isSysAdmin) return this.memberService.getOrgMembershipByUserId(user.id);

        return this.http
          .get<Page<OrgModel>>(`${SERVER_API_URL}/org`, {
            params: this.urlUtil.buildSearchParams('', 0, 1000, ['name'])
          })
          .pipe(
            map((orgs) =>
              orgs.content.map((org) => ({
                orgId: org.id,
                orgName: org.name,
                orgFunction: org.orgType?.orgFunction,
                admin: true,
                orgFeatures: org.features,
                quoteLinqRole: org.features?.includes(FeatureType.quoteLinqEnabled) ? ProductRole.MANAGER : null,
                quoteLinqRxRole: org.features?.includes(FeatureType.quoteLinqRxEnabled) ? ProductRole.MANAGER : null,
                smartLinqRole: org.features?.includes(FeatureType.smartLinqEnabled) ? ProductRole.MANAGER : null
              }))
            )
          );
      }),
      tap((orgMemberships) => this.store.dispatch(new OrgMemberships.GetSuccess(orgMemberships)))
    );
  }

  @Action(OrgMemberships.GetSuccess)
  getOrgMembershipsSuccess(ctx: StateContext<OrgMembershipStateModel>, { orgMemberships }: OrgMemberships.GetSuccess) {
    ctx.patchState({ orgMemberships });
  }
}
