import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { WorkspaceDialogData } from '@common/dialogs/dialogs.model';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { FindRequestModel } from '@common/models/find-request.model';
import { Page } from '@common/models/page.model';
import { SupportFileService } from '@common/models/support-files-service.model';
import { QueryModel } from '@data-table-lib/data-table-config-source';
import { FileDocType, FileModel } from '@file-upload-lib/file.model';
import { UploadService } from '@file-upload-lib/upload.service';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { FilterConfig, HttpResourceService } from '@main/http-resource.service';
import { OcrTemplateModel } from '@main/org-ocr-config/model/org-ocr-config.model';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { declinedReasonOptions } from '@common/components/quote-response-list/quote-response-list.definitions';
import {
  ConfirmationDialogAction,
  ConfirmationDialogType,
  QuoteResponseMinModel,
  QuoteResponseModel,
  QuoteResponseOption,
  QuoteResponseOptionPayScheduleType,
  QuoteResponseOptionTermType,
  QuoteResponseReason,
  QuoteResponseStatus,
  QuoteResponseType,
  QuoteResponseUpdateStatusModalData,
  RateType
} from '@common/models/quote-response.model';
import { PreferredAccountCriteriaModel } from '@common/models/preferred-accounts.model';
import { combineLatest, forkJoin, noop, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FilterUtilService } from '@common/services/filter-util.service';
import { HttpRequestType } from '@shared/components/file-download-progress/file-download.service';
import {
  QuoteResponseRxModel,
  rxDeclinedReasonOptions,
  rxResponseTypeOptionsDef
} from '@common/models/quote-response-rx.model';
import { isStopLossRespondingOrg } from '@common/models/org-type.model';
import { QuoteResponseStepperComponent } from '@quote/quote-response/quote-response-stepper/quote-response-stepper.component';
import { MatDialog } from '@angular/material/dialog';
import { isValidDateAndInRecentCentury } from '@app/tools/date';
import { PolicyCoverageType } from '@smart/component/policy/model/policy.model';
import { ManifestEntryModel, SourceType } from '@common/models/manifest-entry.model';
import { QuoteResponseSubmitComponent } from '@quote/quote-response/quote-response-submit/quote-response-submit.component';
import { QuoteLinqVersionModel } from '@common/models/quote-linq-version.model';
import { QuoteLinqVersionService } from '@common/services/quote-linq-version.service';

const baseUrl = `${SERVER_API_URL}/quote-response`;
const accountSiteFilterConfig: FilterConfig = {
  siteFilterKey: SiteFilterOptions.ACCOUNT,
  dataKey: 'quoteRequest.account.id'
};

const requesterOrgSiteFilterConfig: FilterConfig = {
  siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
  dataKey: 'quoteRequest.quoteType.org.id'
};

const showEffectiveDateSiteFilterConfig: FilterConfig = {
  siteFilterKey: SiteFilterOptions.EFFECTIVE_DATE_RANGE,
  dataKey: 'quoterequest.effectivedate'
};

const showUnderwriterFilterConfig: FilterConfig = {
  siteFilterKey: SiteFilterOptions.UNDERWRITER,
  dataKey: 'org.id'
};

const responderOrgSiteFilterConfig: FilterConfig = {
  siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
  dataKey: 'org.id'
};

export const requesterConfigs: FilterConfig[] = [accountSiteFilterConfig, requesterOrgSiteFilterConfig];
export const requesterUnderwriterConfigs: FilterConfig[] = [
  accountSiteFilterConfig,
  requesterOrgSiteFilterConfig,
  showUnderwriterFilterConfig
];
export const responderConfigs: FilterConfig[] = [accountSiteFilterConfig, responderOrgSiteFilterConfig];
export const responderUnderwriterConfigs: FilterConfig[] = [
  accountSiteFilterConfig,
  responderOrgSiteFilterConfig,
  showUnderwriterFilterConfig
];

// default values
const defaultWidth = '600px';

@Injectable({
  providedIn: 'root'
})
export class QuoteResponseService implements SupportFileService {
  private quoteResponseUpdated = new Subject<QuoteResponseModel>(); // a subject that fires when the update is called
  private quoteResponseStatusUpdated = new Subject<QuoteResponseModel>(); // a subject that fires when the status update is called
  private _siteFilterConfig$ = combineLatest([
    this.store.select(SiteFilterSelectors.isRequestingOrg), // watch for org change to update the site filter config
    this.store.select(SiteFilterSelectors.showEffectiveDateFilter), // watch for the effective date filter show/hide to update the site filter config
    this.store.select(SiteFilterSelectors.showUnderwriterFilter), // watch for the underwriter show/hide to update
    this.store.select(SiteFilterSelectors.selectedEffectiveDateRange), // watch for date range change to trigger the queries (site filter config doesn't change)
    this.store.select(SiteFilterSelectors.selectedUnderwriters) // watch for the underwriter selection to fire off the queries
  ]).pipe(
    map(([isReq, showEffectiveDateFilter, showUnderwriterFilter]) => {
      const config = isReq ? [...requesterConfigs] : [...responderConfigs];
      const index = config.findIndex((x) => x.siteFilterKey === SiteFilterOptions.EFFECTIVE_DATE_RANGE);
      if (showEffectiveDateFilter?.hide === true && index >= 0) {
        config.splice(index, 1);
      } else if (showEffectiveDateFilter?.hide === false && index < 0) {
        config.push(showEffectiveDateSiteFilterConfig);
      }

      const uwIndex = config.findIndex((x) => x.siteFilterKey === SiteFilterOptions.UNDERWRITER);
      if (showUnderwriterFilter?.hide === true && uwIndex >= 0) {
        config.splice(uwIndex, 1);
      } else if (showUnderwriterFilter?.hide === false && uwIndex < 0) {
        config.push(showUnderwriterFilterConfig);
      }
      return config;
    })
  );

  constructor(
    protected http: HttpClient,
    private uploadService: UploadService,
    private httpResource: HttpResourceService,
    private dialogService: DialogsService,
    private store: Store,
    private filterUtilService: FilterUtilService,
    private dialog: MatDialog,
    private quoteLinqVersionService: QuoteLinqVersionService
  ) {}

  statusUpdateObservable(): Observable<QuoteResponseModel> {
    return this.quoteResponseStatusUpdated.asObservable();
  }

  quoteResponseUpdateObservable(): Observable<QuoteResponseModel> {
    return this.quoteResponseUpdated.asObservable();
  }

  searchQuoteResponses(
    filterString: string,
    siteFilterConfigOverride?: FilterConfig[],
    pageIndex = 0,
    pageSize = 0,
    sort: string[] = []
  ): Observable<Page<QuoteResponseMinModel>> {
    return this._siteFilterConfig$.pipe(
      switchMap((siteFilterConfig) =>
        this.httpResource.queryWithoutOrg({
          path: 'quote-response',
          query: {
            filterString: this.filterUtilService.filterByQuoteProduct(filterString, 'quoterequest.quotetype.product'),
            pageIndex,
            pageSize,
            sort
          },
          siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
        })
      )
    );
  }

  searchAggQuoteResponses(
    findReq: FindRequestModel,
    siteFilterConfigOverride?: FilterConfig[]
  ): Observable<Page<QuoteResponseMinModel>> {
    findReq.query = this.filterUtilService.filterByQuoteProduct(findReq.query, 'quoterequest.quotetype.product');
    return this._siteFilterConfig$.pipe(
      switchMap((siteFilterConfig) =>
        this.httpResource.queryAggWithoutOrg({
          path: 'quote-response',
          findReq,
          siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
        })
      )
    );
  }

  searchProposalOptions(query: QueryModel): Observable<Page<QuoteResponseOption>> {
    return this._siteFilterConfig$.pipe(
      switchMap((siteFilterConfig) => {
        query.filterString = this.filterUtilService.filterByQuoteProduct(
          query.filterString,
          'quoterequest.quotetype.product'
        );
        return this.httpResource.queryWithoutOrg({
          path: 'quote-response/option',
          query,
          siteFilterConfig
        });
      })
    );
  }

  searchAggQuoteResponseOptions(findReq: FindRequestModel): Observable<Page<QuoteResponseOption>> {
    return this._siteFilterConfig$.pipe(
      switchMap((siteFilterConfig) => {
        findReq.query = this.filterUtilService.filterByQuoteProduct(findReq.query, 'quoterequest.quotetype.product');
        return this.httpResource.queryAggWithoutOrg({
          path: 'quote-response/option',
          findReq,
          siteFilterConfig
        });
      })
    );
  }

  setQuoteResponseTags(quoteResponse: QuoteResponseModel, tagIds: Array<number>): Observable<any> {
    return this.http.put(
      `${SERVER_API_URL}/quote-response/${quoteResponse.id}/tag?tagIds=${tagIds ? tagIds.map((t) => t).join(',') : ''}`,
      null
    );
  }

  updateQuoteResponseRx(quoteResponseRx: QuoteResponseRxModel): Observable<QuoteResponseRxModel> {
    return this.http.put(`${SERVER_API_URL}/quote-response-rx/${quoteResponseRx.quoteResponseId}`, quoteResponseRx);
  }

  createBulkQuoteResponse(
    quoteRequestId: number,
    orgInvites: Array<{ orgId: number; sendRapidQuote: boolean }>
  ): Observable<QuoteResponseModel[]> {
    return this.http.post<QuoteResponseModel[]>(`${baseUrl}/quote-request/${quoteRequestId}/bulk`, orgInvites);
  }

  getById(id: number): Observable<QuoteResponseModel> {
    return this.http.get(`${baseUrl}/${id}`);
  }

  delete(id: number): Observable<QuoteResponseModel> {
    return this.http.delete(`${baseUrl}/${id}`);
  }

  getPreferredAccountCriteria(id: number): Observable<PreferredAccountCriteriaModel> {
    return this.http.get(`${baseUrl}/${id}/preferred-account-criteria`);
  }

  getSupportFiles(id: number): Observable<FileModel[]> {
    return this.http.get<FileModel[]>(`${baseUrl}/${id}/supportfile`);
  }

  getSupportFile(qrId: number, fileId: string): Observable<FileModel> {
    return this.http.get<FileModel>(`${baseUrl}/${qrId}/supportfile/${fileId}`);
  }

  downloadSupportFile(id: number, fileId: string, fileName: string): Observable<Blob> {
    const path = `quote-response/${id}/supportfile/${fileId}/stream`;
    return this.httpResource.downloadFileWithoutOrg({ path }, { fileName });
  }

  deleteSupportFile(qrId: number, fileId: string): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${qrId}/supportfile/${fileId}`);
  }

  updateSupportFileMetaData(
    qrId: number,
    fileId: string,
    name: string,
    docType: FileDocType,
    desc: string
  ): Observable<FileModel> {
    let params = new HttpParams().set('name', name);

    if (docType) {
      params = params.set('docType', docType);
    }

    if (desc) {
      params = params.set('description', desc);
    }
    return this.http.put<FileModel>(`${baseUrl}/${qrId}/supportfile/${fileId}`, null, { params: params });
  }

  /**
   * Update the status of the Quote Response.
   * When a 'reason' is also provided, make a subsequent call to the updateReason API, after the status update has been completed.
   */
  updateStatus(
    qrId: number,
    status: QuoteResponseStatus,
    reason?: QuoteResponseReason,
    reasonText?: string,
    type?: QuoteResponseType
  ): Observable<QuoteResponseModel> {
    return this.http
      .put<QuoteResponseModel>(`${baseUrl}/${qrId}/status`, {
        status,
        reason,
        reasonText
      })
      .pipe(
        mergeMap((quoteResponse) => {
          if (!reason && !type) {
            return of(quoteResponse);
          }

          if (!!reason) {
            return this.updateReason(quoteResponse.id, reason, reasonText);
          }
          return this.updateType(quoteResponse.id, type);
        }),
        tap((value) => {
          this.quoteResponseStatusUpdated.next(value);
        })
      );
  }

  updateType(quoteResponseId: number, type: QuoteResponseType) {
    return this.http.put(`${baseUrl}/${quoteResponseId}/type`, {
      type: type
    });
  }

  updateReason(quoteResponseId: number, reason: QuoteResponseReason, reasonText: string): Observable<any> {
    return this.http.put(`${baseUrl}/${quoteResponseId}/reason`, {
      reason: reason,
      reasonText: reasonText
    });
  }

  uploadSupportFiles(file: File, responseId: number, docType: FileDocType, notify: boolean = true) {
    return this.uploadService.uploadFile(`${baseUrl}/${responseId}/supportfile?notify=${notify}`, file, {
      docType: docType
    });
  }

  uploadFinalProposalFile(file: File, responseId: number, notify: boolean = false) {
    return this.uploadService.uploadFile(`${baseUrl}/${responseId}/supportfile?notify=${notify}`, file, {
      docType: FileDocType.PROPOSAL
    });
  }

  /**
   * Update Quote Response to new status value
   * This method will open up the relevant modals to prompt the user to confirm the update,
   * and also potentially request additional information (i.e. Decline Reason, Final Contract).
   * Also, in certain combinations of status transition and org function a stepper modal may also be initiated
   * the stepperDialogCallback is a function that can be passed in that will be executed when the stepper is closed with a value
   * therefore allowing the caller to then do whatever work is necessary for that part of the application
   * This logic exists here because it is used in several areas of the app. It is used in the
   * sell side proposal display card header, offline carrier's quote response detail view page, and
   * also in the status workflow modal.
   */
  confirmUpdateProposalStatus(
    quoteResponse: QuoteResponseModel,
    newStatus: string,
    stepperDialogCallback: Function = noop,
    quoteLinqVersion: QuoteLinqVersionModel = null,
    hasImportCapabilities: boolean = false
  ): Observable<QuoteResponseModel> {
    const afterUpdatedFunction =
      newStatus === 'UNDERWRITING' && isStopLossRespondingOrg(quoteResponse.org.orgType.orgFunction)
        ? (quoteResponse, action) => {
            if (action === ConfirmationDialogAction.PRIMARY) {
              this.openQuoteResponseStepper(quoteResponse)
                .afterClosed()
                .pipe(filter((result) => !!result?.id))
                .subscribe((qr) => {
                  stepperDialogCallback(qr);
                });
            }
          }
        : noop;

    const dialogType = QuoteResponseUpdateStatusModalData[newStatus].confirmationDialogType;
    return this.confirmProposalStatusUpdate(
      quoteResponse,
      QuoteResponseStatus[newStatus],
      dialogType,
      afterUpdatedFunction,
      quoteLinqVersion,
      hasImportCapabilities
    );
  }

  getManifestOptions(quoteResponseId: number): Observable<ManifestEntryModel[]> {
    return this.getSupportFiles(quoteResponseId).pipe(
      map((files) =>
        files.map(
          (f) =>
            ({
              source: SourceType.RMT_FILE,
              rmtFile: f
            }) as ManifestEntryModel
        )
      )
    );
  }

  downloadQuoteResponseManifest(quoteResponseId: number, qrPackage, fileName: string): Observable<Blob> {
    return this.httpResource.downloadFileWithoutOrg(
      { path: `quote-response/${quoteResponseId}/manifest/download` },
      { body: qrPackage, httpType: HttpRequestType.POST, fileName }
    );
  }

  getOcrTemplates(responseId: number): Observable<OcrTemplateModel[]> {
    return this.http.get<OcrTemplateModel[]>(`${baseUrl}/${responseId}/ocr-template`);
  }

  /**
   * Only include statuses that are valid transitions given the current status
   *
   * Return an empty list if there are no valid states to change to.
   */
  getValidStatuses(currStatus: QuoteResponseStatus): Array<QuoteResponseStatus> {
    const isRequester = this.store.selectSnapshot(SiteFilterSelectors.isRequestingOrg);
    switch (currStatus) {
      case QuoteResponseStatus.NEW:
        return isRequester
          ? [
              QuoteResponseStatus.UNDERWRITING,
              QuoteResponseStatus.DECLINED,
              QuoteResponseStatus.SUBMITTED,
              QuoteResponseStatus.LOST
            ]
          : [QuoteResponseStatus.UNDERWRITING, QuoteResponseStatus.DECLINED, QuoteResponseStatus.SUBMITTED];

      case QuoteResponseStatus.UNDERWRITING:
        return isRequester
          ? [
              QuoteResponseStatus.NEW,
              QuoteResponseStatus.SUBMITTED,
              QuoteResponseStatus.DECLINED,
              QuoteResponseStatus.LOST
            ]
          : [QuoteResponseStatus.NEW, QuoteResponseStatus.SUBMITTED, QuoteResponseStatus.DECLINED];

      case QuoteResponseStatus.SUBMITTED:
        return isRequester
          ? [
              QuoteResponseStatus.NEW,
              QuoteResponseStatus.DECLINED,
              QuoteResponseStatus.UNDERWRITING,
              QuoteResponseStatus.SHORTLIST,
              QuoteResponseStatus.LOST
            ]
          : [QuoteResponseStatus.NEW, QuoteResponseStatus.DECLINED, QuoteResponseStatus.UNDERWRITING];

      case QuoteResponseStatus.SHORTLIST:
        return isRequester
          ? [
              QuoteResponseStatus.NEW,
              QuoteResponseStatus.UNDERWRITING,
              QuoteResponseStatus.LOST,
              QuoteResponseStatus.DECLINED
            ]
          : [QuoteResponseStatus.DECLINED];

      case QuoteResponseStatus.DECLINED:
        return isRequester ? [QuoteResponseStatus.NEW, QuoteResponseStatus.UNDERWRITING, QuoteResponseStatus.LOST] : [];

      case QuoteResponseStatus.LOST:
        return isRequester
          ? [QuoteResponseStatus.NEW, QuoteResponseStatus.DECLINED, QuoteResponseStatus.UNDERWRITING]
          : [];

      case QuoteResponseStatus.WON:
        return isRequester
          ? [QuoteResponseStatus.SUBMITTED, QuoteResponseStatus.BOUND, QuoteResponseStatus.DECLINED]
          : [];

      default:
        return [];
    }
  }

  /**
   * afterUpdate function is a callback executed after the updateStatus API call is made
   * allowing the caller to take an action after the call, i.e. open an edit stepper modal
   */
  confirmProposalStatusUpdate(
    quoteResponse: QuoteResponseModel,
    newStatus: QuoteResponseStatus,
    dialogType: ConfirmationDialogType,
    afterUpdated?: Function,
    quoteLinqVersion?: QuoteLinqVersionModel,
    hasImportCapabilities?: boolean
  ): Observable<any> {
    // if the new status is DTQ, then add the required formModel data for the confirmation dialog
    const workspaceDialogData: WorkspaceDialogData = {
      quoteResponse: quoteResponse,
      dialogType: dialogType
    };

    if (
      dialogType === ConfirmationDialogType.ACCEPT_INVITATION &&
      isStopLossRespondingOrg(quoteResponse.org.orgType.orgFunction)
    ) {
      workspaceDialogData.customActionText = `Accept & ${hasImportCapabilities ? 'Import' : 'Upload'} PDF`;
      workspaceDialogData.customSecondaryActionText = 'Accept';
    } else if (
      dialogType === ConfirmationDialogType.SUBMIT_QUOTE &&
      quoteResponse.org?.orgType?.orgFunction !== 'PBM'
    ) {
      // if this version doesn't have a rmt file yet, open the stepper then the submit modal otherwise just the submit modal
      return quoteLinqVersion?.rmtFile
        ? this.dialogService
            .openDialog(
              QuoteResponseSubmitComponent,
              { quoteResponse: JSON.parse(JSON.stringify(quoteResponse)), version: quoteLinqVersion },
              { disableClose: true },
              '60vw'
            )
            .afterClosed()
        : this.openQuoteResponseStepper(quoteResponse, true).afterClosed();
    } else if (dialogType === ConfirmationDialogType.DTQ) {
      workspaceDialogData.formModel = {
        sections: [{ key: 'declineReason', label: null }],
        fields: [
          new DataDefModel({
            label: 'Reason',
            key: 'reason',
            sectionKey: 'declineReason',
            validators: { required: true },
            type: DATA_TYPES.select,
            options: quoteResponse.org.orgType.orgFunction !== 'PBM' ? declinedReasonOptions : rxDeclinedReasonOptions
          }),
          new DataDefModel({
            label: 'Additional Details',
            sectionKey: 'declineReason',
            key: 'reasonText',
            type: DATA_TYPES.text
          })
        ]
      };
    } else if (dialogType === ConfirmationDialogType.SUBMIT_QUOTE && quoteResponse.org.orgType.orgFunction === 'PBM') {
      workspaceDialogData.formModel = {
        sections: [{ key: 'type', label: null }],
        fields: [
          new DataDefModel({
            label: 'Proposal Status',
            key: 'type',
            sectionKey: 'type',
            validators: { required: true },
            type: DATA_TYPES.select,
            options: rxResponseTypeOptionsDef
          })
        ]
      };
    }
    return this.dialogService
      .proposalConfirmationDialog(workspaceDialogData)
      .afterClosed()
      .pipe(
        filter((val) => !!val),
        mergeMap((confirmationDialogResponse) =>
          this.updateStatus(
            quoteResponse.id,
            newStatus,
            confirmationDialogResponse.reason,
            confirmationDialogResponse.reasonText,
            confirmationDialogResponse.type
          ).pipe(
            tap((quoteResponse) => {
              afterUpdated(quoteResponse, confirmationDialogResponse.action);
            })
          )
        )
      );
  }

  deleteQuoteResponseWithConfirmation(quoteResponse: QuoteResponseModel): Observable<QuoteResponseModel> {
    return this.dialogService
      .confirmationDialog({
        label: 'Delete Quote',
        message: `<b>Quote ID ${quoteResponse.id}</b>  and all associated data will be permanently deleted. Are you sure you want to continue?`,
        actionText: 'Delete'
      })
      .afterClosed()
      .pipe(
        filter((value) => !!value),
        mergeMap((_) => this.delete(quoteResponse.id))
      );
  }

  isActiveStatus(status: QuoteResponseStatus): boolean {
    return (
      status === QuoteResponseStatus.BOUND ||
      status === QuoteResponseStatus.NEW ||
      status === QuoteResponseStatus.SHORTLIST ||
      status === QuoteResponseStatus.SUBMITTED ||
      status === QuoteResponseStatus.UNDERWRITING
    );
  }

  openQuoteResponseStepper(quoteResponse: QuoteResponseModel, submitOnly: boolean = false) {
    return this.dialog.open(QuoteResponseStepperComponent, {
      width: '90%',
      height: '90%',
      panelClass: 'dialog-sidebar',
      disableClose: true,
      data: (): Observable<
        [QuoteResponseModel, OcrTemplateModel[], QuoteLinqVersionModel, boolean, QuoteLinqVersionModel]
      > =>
        forkJoin([
          of(JSON.parse(JSON.stringify(quoteResponse))),
          this.getOcrTemplates(quoteResponse.id),
          of(null),
          of(submitOnly),
          this.quoteLinqVersionService?.getLatest(quoteResponse.id)
        ])
    });
  }

  cleanQuoteResponseModel(impData: any): QuoteResponseModel {
    // when the import data does not pass through the formlib, the dates are still strings and typically a M/d/yyyy
    //   this means that when applying to the quote response, it must be converted to a date object or else the server
    //   will error.
    // this logic will support the following formats M/d/yyyy, yyyy-M-d, M-d-yyyy
    //  these are all expected to be in this century.  Sometimes the OCR returns invalid dates outside this century, just remove those for these
    //  particular dates because business dictates they should always be in this century
    if (impData?.expirationDate && !isValidDateAndInRecentCentury(impData.expirationDate)) {
      impData.expirationDate = this.cleanDateForRecentCentury(impData.expirationDate);
    }
    if (impData?.validThroughDate && !isValidDateAndInRecentCentury(impData.validThroughDate)) {
      impData.validThroughDate = this.cleanDateForRecentCentury(impData.validThroughDate);
    }
    if (impData?.effectiveDate && !isValidDateAndInRecentCentury(impData.effectiveDate)) {
      impData.effectiveDate = this.cleanDateForRecentCentury(impData.effectiveDate);
    }
    if (impData?.proposalDate && !isValidDateAndInRecentCentury(impData.proposalDate)) {
      impData.proposalDate = this.cleanDateForRecentCentury(impData.proposalDate);
    }
    // sometimes the coverages come back from OCR with malformed data.  Clean those enums as well
    if (impData?.quoteResponseOptions?.length > 0) {
      impData.quoteResponseOptions.forEach((opt) => {
        // clean coverages
        if (opt.specCoverage?.length > 0) {
          opt.specCoverage = this.cleanPolicyCoverageType(opt.specCoverage);
        }
        if (opt.aggCoverage?.length > 0) {
          opt.aggCoverage = this.cleanPolicyCoverageType(opt.aggCoverage);
        }
        // clean rate types
        opt.specRateSchedule = this.cleanRateType(opt.specRateSchedule);
        opt.aggFactorSchedule = this.cleanRateType(opt.aggFactorSchedule);
        opt.aggRateSchedule = this.cleanRateType(opt.aggRateSchedule);
        // clean term types
        opt.specTermLiabilityTerm = this.cleanTermType(opt.specTermLiabilityTerm);
        opt.aggTermLiabilityTerm = this.cleanTermType(opt.aggTermLiabilityTerm);
        // clean PayScheduleType
        opt.aggPaymentSchedule = this.cleanPayScheduleType(opt.aggPaymentSchedule);
      });
    }
    return impData;
  }

  private cleanDateForRecentCentury(date: any): Date {
    const res = new Date(date);
    return isValidDateAndInRecentCentury(res) ? res : null;
  }

  private cleanPolicyCoverageType(coverage: any[]): PolicyCoverageType[] {
    return coverage
      .map((cov) => PolicyCoverageType[cov as string as keyof typeof PolicyCoverageType])
      .filter((c) => !!c); // make sure the values parse into known enum values
  }

  private cleanRateType(type: any): RateType {
    const rt = RateType[type as string as keyof typeof RateType];
    return !rt ? null : rt;
  }

  private cleanTermType(type: any): QuoteResponseOptionTermType {
    const rt = QuoteResponseOptionTermType[type as string as keyof typeof QuoteResponseOptionTermType];
    return !rt ? null : rt;
  }

  private cleanPayScheduleType(type: any): QuoteResponseOptionPayScheduleType {
    const rt = QuoteResponseOptionPayScheduleType[type as string as keyof typeof QuoteResponseOptionPayScheduleType];
    return !rt ? null : rt;
  }
}
