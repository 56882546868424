import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import {
  ExtractAuditModel,
  ExtractTextModel
} from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { QuoteResponseModel } from '@common/models/quote-response.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExtractTextService {
  constructor(private http: HttpClient) {}

  extractRmtXmlText(
    responseId: number,
    pdfFile: File,
    xmlFile: File,
    supportingFile: File,
    storeFiles: boolean = false
  ): Observable<ExtractTextModel> {
    const formData: FormData = new FormData();
    formData.append('pdfFile', pdfFile, pdfFile.name);
    formData.append('xmlFile', xmlFile, xmlFile.name);
    if (supportingFile) {
      formData.append('supportingFile', supportingFile, supportingFile.name);
    }
    return this.http
      .post<QuoteResponseModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/field-extract-rmt?storeFiles=${storeFiles}`,
        formData
      )
      .pipe(
        map((res) => ({
          fields: null,
          extractedObj: res
        }))
      );
  }

  extractDyXmlText(
    responseId: number,
    pdfFile: File,
    xmlFile: File,
    storeFiles: boolean = true
  ): Observable<ExtractTextModel> {
    const formData: FormData = new FormData();
    formData.append('pdfFile', pdfFile, pdfFile.name);
    formData.append('xmlFile', xmlFile, xmlFile.name);
    return this.http
      .post<QuoteResponseModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/field-extract-dy?storeFiles=${storeFiles}`,
        formData
      )
      .pipe(
        map((res) => ({
          fields: null,
          extractedObj: res
        }))
      );
  }

  extractOcrText(responseId: number, templateId: number, fileId: string, file: File): Observable<ExtractAuditModel> {
    if (fileId) {
      return this.http.get<ExtractAuditModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/field-extract/${templateId}?rmtFileId=${fileId}&async=true`
      );
    }

    const formData: FormData = new FormData();
    formData.append('pdfFile', file, file.name);
    return this.http.post<ExtractAuditModel>(
      `${SERVER_API_URL}/quote-response/${responseId}/field-extract/${templateId}?async=true`,
      formData
    );
  }

  getExtractAudit(extractAuditId: number, responseId: number): Observable<ExtractAuditModel> {
    return this.http.get<ExtractAuditModel>(
      `${SERVER_API_URL}/quote-response/${responseId}/extract-audit/${extractAuditId}`
    );
  }

  getExtractOcrResult(extractAuditId: number, responseId: number, file?: File): Observable<ExtractTextModel> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('pdfFile', file, file.name);
    }

    let call = file
      ? this.http.post<OcrModel[]>(
          `${SERVER_API_URL}/quote-response/${responseId}/field-extract-result/${extractAuditId}`,
          formData
        )
      : this.http.get<OcrModel[]>(
          `${SERVER_API_URL}/quote-response/${responseId}/field-extract-result/${extractAuditId}`
        );

    return call.pipe(
      filter((val) => !!val),
      // convert the OCR field data into the shape of an extracted object, it turns the dot referenced fields into objects or arrays
      map((result) => ({ fields: result, extractedObj: this.extractObj(result) }))
    );
  }

  private extractObj(result: OcrModel[]): QuoteResponseModel {
    const importObj: QuoteResponseModel = {};
    result
      .flatMap((ocrModel) => ocrModel.fields)
      .forEach((field) => {
        field.key.split('.').reduce((obj, fieldName, idx, origArray) => {
          const arrayFound = fieldName.match('(.*)\\[(\\d+)\\]');
          if (arrayFound) {
            // an array field type, break out the index from the capture groups
            const arrayField = arrayFound[1]; // index 1 is the first capture group
            const arrayIndex = arrayFound[2]; // index 2 is the second capture group
            if (!obj[arrayField]) {
              obj[arrayField] = [];
            }
            if (idx === origArray.length - 1) {
              // the array is a set of field values
              obj[arrayField][arrayIndex] = field.value; // add or overwrite the value at the array index
            } else if (!obj[arrayField][arrayIndex]) {
              obj[arrayField][arrayIndex] = {}; // no value at the array index
            }
            return obj[arrayField][arrayIndex];
          }

          if (idx === origArray.length - 1) {
            // if this is the end of the array and the actual field, set the value
            obj[fieldName] = field.value;
          } else if (!obj[fieldName]) {
            obj[fieldName] = {}; // deeper structure, create the object
          }
          return obj[fieldName];
        }, importObj);
      });

    // check all the indices of the quote response options and fill in undefined options
    // it is possible the ocr didn't find any value for sequential option numbers and left some undefined objects in the importObj
    // for example, fields only came back for quoteResponseOption[3] and quoteResponseOption[4] that left quoteResponseOption 0, 1, and 2 undefined
    if (importObj.quoteResponseOptions) {
      for (let i = 0; i < importObj.quoteResponseOptions.length; i++) {
        if (importObj.quoteResponseOptions[i] === undefined) {
          importObj.quoteResponseOptions[i] = {};
        }
      }
    }

    return importObj;
  }
}
