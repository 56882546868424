<app-vertical-stepper
  *ngIf="quoteLinqVersion"
  [dialogTitle]="
    (readOnly ? 'View Proposal' : 'Edit Proposal') +
    (quoteLinqVersion ? ' | Version ' + quoteLinqVersion.versionId : '')
  "
  [actions]="stepperActions"
  [saving]="saving"
  [readOnly]="readOnly"
  [appInProgressIndicator]="loading"
  (navigationStart)="navigate($event)"
  (onSubmit)="submit($event)"
  [ngClass]="{ 'initial-load': initialLoad, 'field-disable': readOnly }"
>
  <span dialogTitleContent *ngIf="!loading">
    @if (!quoteLinqVersion.rmtFile || quoteLinqVersion.versionStatus === 'DRAFT') {
      <span
        *ngIf="
          !readOnly && !ocrData && !manualImportOnly && (dyXmlEnabled || ocrTemplates?.length > 0 || rmtXmlEnabled)
        "
        class="dialog-message"
      >
        <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
        @if (quoteLinqVersion.id > 0) {
          Edit or re-import your proposal to continue.
        } @else {
          Import or Edit your proposal to continue.
        }
      </span>
      <span *ngIf="ocrData && !readOnly" class="dialog-message">
        <fa-icon [icon]="warningIcon" class="color-status-orange-dark"></fa-icon>
        Review all fields for accuracy.
      </span>
      <span
        *ngIf="
          !readOnly && !ocrData && !manualImportOnly && !(dyXmlEnabled || ocrTemplates?.length > 0 || rmtXmlEnabled)
        "
        class="dialog-message"
      >
        <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
        Edit your proposal to continue.
      </span>
      <button (click)="importProposalText()" *ngIf="!readOnly" class="btn-reset btn-anchor ml-3 import-link">
        {{
          (dyXmlEnabled || ocrTemplates?.length > 0 || rmtXmlEnabled) && !manualImportOnly
            ? 'Import PDF'
            : !!quoteResponse.id
              ? 'Upload PDF'
              : ''
        }}
      </button>
    }
    @if (quoteLinqVersion.rmtFile) {
      <span class="file-link">
        <button (click)="displayVersionProposal()" type="button" class="btn-reset btn-anchor import-link">
          <fa-icon [icon]="fileIcon" class="mr-1"></fa-icon>{{ quoteLinqVersion.rmtFile.name }}
        </button>
      </span>
    } @else if (unsavedProposal) {
      <span class="file-link">
        <button (click)="displayLoadedFile()" type="button" class="btn-reset btn-anchor import-link">
          <fa-icon [icon]="fileIcon" class="mr-1"></fa-icon>{{ unsavedProposal.file.name }}
        </button>
      </span>
    }
  </span>
  <cdk-step label="General Information" [hasError]="stepInvalid(0)">
    <div class="rmt-large-subsection-header">Step 1. Confirm General Information</div>
    <app-quote-response-general-information
      *ngIf="!loading"
      [quoteResponse]="quoteResponse"
      [touched]="stepIsVisited(0)"
      [readOnly]="readOnly"
      [ocrData]="ocrData"
      [importData]="importData"
      (update)="updatedGeneralInformation($event)"
      (updateValidity)="updatedGeneralValidity($event)"
    >
    </app-quote-response-general-information>
  </cdk-step>
  <cdk-step label="Policy" [hasError]="stepInvalid(1)">
    <div class="rmt-large-subsection-header step-header">
      Step 2. Confirm Policy Details
      <button
        class="add-button"
        mat-raised-button
        *ngIf="!readOnly"
        (click)="addOption()"
        [appInProgressIndicator]="addingOption || quoteResponsePolicy?.policyForm?.renderingLayout"
      >
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <div [appInProgressIndicator]="addingOption || quoteResponsePolicy?.policyForm?.renderingLayout">
      <app-quote-response-policy
        #quoteResponsePolicy
        *ngIf="!loading"
        [quoteResponse]="quoteResponse"
        [readOnly]="readOnly"
        [ocrData]="ocrData"
        [importData]="importData"
        (update)="updatedPolicy($event)"
        (cloneOption)="cloneOption($event)"
        (removeOption)="removeOption($event)"
      >
      </app-quote-response-policy>
    </div>
  </cdk-step>
  <cdk-step label="Specific" [hasError]="stepInvalid(2)">
    <div class="rmt-large-subsection-header step-header">
      Step 3. Confirm Specific Details
      <button
        class="add-button"
        mat-raised-button
        *ngIf="!readOnly"
        (click)="addOption()"
        [appInProgressIndicator]="addingOption || quoteResponseSpecific?.specificForm?.renderingLayout"
      >
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <div [appInProgressIndicator]="addingOption || quoteResponseSpecific?.specificForm?.renderingLayout">
      <app-quote-response-specific
        #quoteResponseSpecific
        *ngIf="!loading"
        [quoteResponse]="quoteResponse"
        [readOnly]="readOnly"
        [ocrData]="ocrData"
        [importData]="importData"
        (update)="updatedSpecific($event)"
        (cloneOption)="cloneOption($event)"
        (removeOption)="removeOption($event)"
      >
      </app-quote-response-specific>
    </div>
  </cdk-step>
  <cdk-step label="Aggregate" [hasError]="stepInvalid(3)">
    <div class="rmt-large-subsection-header step-header">
      Step 4. Confirm Aggregate Details
      <button
        class="add-button"
        mat-raised-button
        *ngIf="!readOnly"
        (click)="addOption()"
        [appInProgressIndicator]="addingOption || quoteResponseAggregate?.aggregateForm?.renderingLayout"
      >
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <div [appInProgressIndicator]="addingOption || quoteResponseAggregate?.aggregateForm?.renderingLayout">
      <app-quote-response-aggregate
        #quoteResponseAggregate
        *ngIf="!loading"
        [quoteResponse]="quoteResponse"
        [readOnly]="readOnly"
        [ocrData]="ocrData"
        [importData]="importData"
        (update)="updatedAgg($event)"
        (cloneOption)="cloneOption($event)"
        (removeOption)="removeOption($event)"
      >
      </app-quote-response-aggregate>
    </div>
  </cdk-step>
  <cdk-step label="Lasers" [hasError]="stepInvalid(4)">
    <div class="rmt-large-subsection-header step-header">
      Step 5. Confirm Laser Details
      <button
        class="add-button"
        mat-raised-button
        *ngIf="!readOnly"
        (click)="addLaser()"
        [appInProgressIndicator]="addingOption"
      >
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <div [appInProgressIndicator]="addingOption || quoteResponseCostSummary?.costSummaryForm?.renderingLayout">
      <app-quote-response-laser
        *ngIf="!loading"
        [uiLasers]="laserUiModels"
        [readOnly]="readOnly"
        (update)="updatedLasers($event)"
      >
      </app-quote-response-laser>
    </div>
  </cdk-step>
  <cdk-step label="Additional Fees" [hasError]="stepInvalid(5)">
    <div class="rmt-large-subsection-header step-header">
      Step 6. Additional Fees
      <button
        class="add-button"
        mat-raised-button
        *ngIf="!readOnly"
        (click)="addAdditionalFee()"
        [appInProgressIndicator]="addingOption"
      >
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <div [appInProgressIndicator]="addingOption || quoteResponseCostSummary?.costSummaryForm?.renderingLayout">
      <app-quote-response-additional-fees
        *ngIf="!loading"
        [uiAdditionalFees]="additionalFeeUiModels"
        [readOnly]="readOnly"
        (update)="updatedAdditionalFees($event)"
      >
      </app-quote-response-additional-fees>
    </div>
  </cdk-step>
  <cdk-step label="Cost Summary" [hasError]="stepInvalid(6)">
    <div class="rmt-large-subsection-header step-header">
      Step 7: Confirm Estimated Costs
      <button
        class="add-button"
        mat-raised-button
        *ngIf="!readOnly"
        (click)="addOption()"
        [appInProgressIndicator]="addingOption || quoteResponseCostSummary?.costSummaryForm?.renderingLayout"
      >
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <div [appInProgressIndicator]="addingOption || quoteResponseCostSummary?.costSummaryForm?.renderingLayout">
      <app-quote-response-cost-summary
        #quoteResponseCostSummary
        *ngIf="!loading"
        [quoteResponse]="quoteResponse"
        [readOnly]="readOnly"
        [ocrData]="ocrData"
        [importData]="importData"
        (update)="updatedCostSummary($event)"
        (cloneOption)="cloneOption($event)"
        (removeOption)="removeOption($event)"
      >
      </app-quote-response-cost-summary>
    </div>
  </cdk-step>
  <cdk-step label="Contingencies" [hasError]="stepInvalid(7)">
    <div class="rmt-large-subsection-header step-header">
      Step 8. Confirm Contingencies
      <button class="add-button" mat-raised-button *ngIf="!readOnly && isLatestVersion" (click)="addContingency()">
        <fa-icon [icon]="addIcon" class="mr-2"></fa-icon>
        Add New
      </button>
    </div>
    <app-contingencies
      *ngIf="!loading"
      [quoteResponseId]="quoteResponse?.id"
      [contingencies]="contingencies"
      [versionFile]="quoteLinqVersion?.rmtFile"
      [unsavedFile]="unsavedProposal"
      [isLatestVersion]="isLatestVersion"
      [readonly]="readOnly"
      (update)="updatedContingencies($event)"
    >
    </app-contingencies>
  </cdk-step>
</app-vertical-stepper>
