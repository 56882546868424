import {
  QuoteResponseModel,
  QuoteResponseReason,
  QuoteResponseStatus,
  QuoteResponseType
} from '@common/models/quote-response.model';
import { QuoteResponseOptionRxModel } from '@common/models/quote-response-option-rx.model';
import { DATA_TYPES, DataDefModel, DataDefOption } from '@lib-resource/data-def.model';
import { QuoteRequestRxTechnologyFeeBilling } from '@common/models/quote-request-rx.model';

export class QuoteResponseRxModel {
  quoteResponseId?: number;
  technologyFee?: number;
  technologyFeeOneTime?: number;
  technologyFeeBilling?: QuoteRequestRxTechnologyFeeBilling;
  quoteResponse?: QuoteResponseModel;
  quoteResponseOptionRxs?: QuoteResponseOptionRxModel[];
  lddSdlConflict?: boolean;
  lddLastUpdated?: string;
  sdlLastUpdated?: string;

  constructor() {
    this.quoteResponse = new QuoteResponseModel();
  }
}

export const rxQuoteResponseStatusOptions: DataDefOption[] = [
  {
    label: 'New',
    value: QuoteResponseStatus.NEW
  },
  {
    label: 'Repricing',
    value: QuoteResponseStatus.UNDERWRITING
  },
  {
    label: 'Submitted',
    value: QuoteResponseStatus.SUBMITTED
  },
  {
    label: 'Shortlist',
    value: QuoteResponseStatus.SHORTLIST
  },
  {
    label: 'Lost',
    value: QuoteResponseStatus.LOST
  },
  {
    label: 'Declined',
    value: QuoteResponseStatus.DECLINED
  },
  {
    label: 'Won',
    value: QuoteResponseStatus.WON
  },
  {
    label: 'Bound',
    value: QuoteResponseStatus.BOUND
  },
  {
    label: 'Closed',
    value: QuoteResponseStatus.CLOSED
  }
];

export const rxDeclinedReasonOptions: DataDefOption[] = [
  { value: QuoteResponseReason.INCOMPLETE_RFP_DATA, label: 'Incomplete request data' },
  { value: QuoteResponseReason.PRICING_NOT_COMPETITIVE, label: 'Pricing not competitive' }
];

export const rxDeclinedReasonDefWithoutLabel: DataDefModel = new DataDefModel({
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true },
  options: rxDeclinedReasonOptions
});

export const rxDeclinedReasonDef: DataDefModel = {
  label: 'Reason',
  ...rxDeclinedReasonDefWithoutLabel
};

export const rxLostReasonOptions: DataDefOption[] = [
  { value: QuoteResponseReason.MISSED_DEADLINE, label: 'Missed deadline' },
  { value: QuoteResponseReason.PRICING_NOT_COMPETITIVE, label: 'Pricing not competitive' },
  { value: QuoteResponseReason.RENEWED_WITH_INCUMBENT, label: 'Renewed with Incumbent' },
  { value: QuoteResponseReason.UNABLE_TO_FIRM_PROPOSAL, label: 'Unable to firm proposal' }
];

export const rxLostReasonDefWithoutLabel: DataDefModel = new DataDefModel({
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true },
  options: rxLostReasonOptions
});

export const rxLostReasonDef: DataDefModel = {
  label: 'Reason',
  ...rxLostReasonDefWithoutLabel
};

export const rxQuoteResponseStatusDef: DataDefModel = new DataDefModel({
  label: 'Stage',
  key: 'status',
  type: DATA_TYPES.enum,
  options: rxQuoteResponseStatusOptions
});

export const rxResponseTypeOptionsDef: DataDefOption[] = [
  {
    label: 'Illustrative',
    value: QuoteResponseType.ILLUSTRATIVE
  },
  {
    label: 'Best and Final Offer',
    value: QuoteResponseType.FINAL
  },
  {
    label: 'Rapid Quote',
    value: QuoteResponseType.FIRM
  }
];

export const rxQuoteResponseTypeDef: DataDefModel = new DataDefModel({
  key: 'type',
  type: DATA_TYPES.select,
  label: 'Proposal Status',
  options: rxResponseTypeOptionsDef,
  validators: { required: true }
});
