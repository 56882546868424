import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StepperNavEvent } from '@common/components/vertical-stepper/vertical-stepper.component';
import { NotificationService } from '@shared/notifications/notification.service';
import { TagModel } from '@configuration/admin/tag-list/tag.model';
import {
  AdditionalFeeModel,
  determineEnrollmentTier,
  FeeRecurrence,
  LaserModel,
  QuoteResponseModel,
  QuoteResponseOption,
  QuoteResponseStatus,
  QuoteResponseType
} from '@common/models/quote-response.model';
import { QuoteResponseService } from '@common/services/quote-response.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faPlusCircle, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { LaserUiModel } from '@quote/quote-response/quote-response-stepper/laser/laserUi.model';
import { ExtractTextDialogComponent } from '@common/components/quote/extract-text-dialog/extract-text-dialog.component';
import { defaultIfEmpty, tap } from 'rxjs/operators';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { OcrTemplateModel } from '@main/org-ocr-config/model/org-ocr-config.model';
import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import { FeatureType } from '@main/org/models/org.model';
import { READ_ONLY_STATUSES } from '@quote/workspace/proposal/proposal-workspace.definitions';
import { QuoteResponseSubmitComponent } from '@quote/quote-response/quote-response-submit/quote-response-submit.component';
import { FileDocType, FileExtModel, FileModel } from '@file-upload-lib/file.model';
import { BaseApiDialogComponent } from '@common/dialogs/base-api-dialog.component';
import { forkJoin, Observable } from 'rxjs';
import { AggregateComponent } from '@quote/quote-response/quote-response-stepper/aggregate/aggregate.component';
import { SpecificComponent } from '@quote/quote-response/quote-response-stepper/specific/specific.component';
import { CostSummaryComponent } from '@quote/quote-response/quote-response-stepper/cost-summary/cost-summary.component';
import { ExtractFileModel, ExtractType } from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { QuoteLinqVersionModel, QuoteLinqVersionStatusType } from '@common/models/quote-linq-version.model';
import { QuoteLinqVersionService } from '@common/services/quote-linq-version.service';
import { QuoteResponseEventType } from '@common/models/quote-response-event.model';
import {
  QuoteSupportingFilePdfDialogComponent,
  QuoteSupportingFilePdfDialogReturn
} from '@common/dialogs/quote-supporting-file-pdf-dialog/quote-supporting-file-pdf-dialog.component';
import { HttpParams } from '@angular/common/http';
import { HttpResourceService } from '@main/http-resource.service';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { ContingencyModel } from '@quote/quote-response/quote-response-stepper/contingencies/contingency.model';
import { PolicyDetailsComponent } from '@quote/quote-response/quote-response-stepper/policy/policy.component';
import { AdditionalFeeUiModel } from '@quote/quote-response/quote-response-stepper/additional-fees/additionalFeeUiModel';

@Component({
  templateUrl: './quote-response-stepper.component.html',
  styleUrls: ['./quote-response-stepper.component.scss']
})
export class QuoteResponseStepperComponent extends BaseApiDialogComponent<
  [QuoteResponseModel, OcrTemplateModel[], QuoteLinqVersionModel, boolean, QuoteLinqVersionModel]
> {
  quoteResponse: QuoteResponseModel;
  quoteLinqVersion: QuoteLinqVersionModel;
  latestVersion: QuoteLinqVersionModel; // only available when creating new versions as a reference for certain fields
  tags: TagModel[];

  submitAction: string;
  saveAction: string;

  initialLoad: boolean = true;
  saving: boolean;
  readOnly: boolean;
  requirePdf: boolean;
  manualImportOnly: boolean;

  // used to indicate that updates should use imported data
  // true after importing data then once the imported data is used
  // the import data can't really be removed as it shows up through the ocr icons but the data will
  // be stored in this.quoteResponse and shouldn't be used again (as it'd be updating with outdated data)
  useImportData: boolean = false;

  validStep: boolean[] = Array(8).fill(true);
  visitedStep: boolean[] = Array(8).fill(false);
  stepperActions: string[];

  addIcon: IconDefinition = faPlusCircle;
  warningIcon: IconDefinition = faWarning;
  infoIcon: IconDefinition = faInfoCircle;
  fileIcon: IconDefinition = faFilePdf;

  laserUiModels: LaserUiModel[];
  additionalFeeUiModels: AdditionalFeeUiModel[];

  ocrTemplates: OcrTemplateModel[];
  ocrData: OcrModel[];
  dyXmlEnabled: boolean;
  rmtXmlEnabled: boolean;
  importData;
  addingOption = false;
  isLatestVersion: boolean;
  contingencies: ContingencyModel[];

  @ViewChild('quoteResponseSpecific') quoteResponseSpecific: SpecificComponent;
  @ViewChild('quoteResponsePolicy') quoteResponsePolicy: PolicyDetailsComponent;
  @ViewChild('quoteResponseAggregate') quoteResponseAggregate: AggregateComponent;
  @ViewChild('quoteResponseCostSummary') quoteResponseCostSummary: CostSummaryComponent;

  filesToSave: FileExtModel[] = [];
  unsavedProposal: FileExtModel;
  savedProposalFile: FileModel;
  importEventType: QuoteResponseEventType;

  constructor(
    private quoteResponseService: QuoteResponseService,
    private quoteLinqVersionService: QuoteLinqVersionService,
    private httpService: HttpResourceService,
    private notificationService: NotificationService,
    private dialogService: DialogsService,
    private dialogRef: MatDialogRef<QuoteResponseStepperComponent>,
    @Inject(MAT_DIALOG_DATA)
    public loadFunction: () => Observable<
      [QuoteResponseModel, OcrTemplateModel[], QuoteLinqVersionModel, boolean, QuoteLinqVersionModel]
    >
  ) {
    super(loadFunction);

    // fighting the steps all rendering at once before they slide out of view, hiding the content for just a moment
    setTimeout(() => {
      this.initialLoad = false;
    }, 100);
  }

  updatedGeneralInformation(updated: { quoteResponse: QuoteResponseModel; valid: boolean }) {
    this.quoteResponse = this.updatedQuoteResponseModel(
      this.useImportData ? [...this.importData.quoteResponseOptions] : [...this.quoteResponse.quoteResponseOptions],
      // make sure the laser confirmation is brought through from the import data
      this.useImportData && this.importData?.laserConfirmation
        ? { ...updated.quoteResponse, laserConfirmation: this.importData?.laserConfirmation }
        : updated.quoteResponse
    );
    this.validStep[0] = updated.valid;
  }

  updatedGeneralValidity(valid: boolean) {
    // just update the validity of the form without updating the model, useful for when importData is applied to this step
    this.validStep[0] = valid;
  }

  updatedPolicy(updated: { quoteResponse: QuoteResponseModel; valid: boolean }) {
    this.updatedOptions(updated);
    this.validStep[1] = updated.valid;
  }

  updatedSpecific(updated: { quoteResponse: QuoteResponseModel; valid: boolean }) {
    this.updatedOptions(updated);
    this.validStep[2] = updated.valid;
  }

  updatedAgg(updated: { quoteResponse: QuoteResponseModel; valid: boolean }) {
    this.updatedOptions(updated);
    this.validStep[3] = updated.valid;
  }

  updatedLasers(updated: { uiLasers: LaserUiModel[]; valid: boolean }) {
    this.laserUiModels = updated.uiLasers;
    this.validStep[4] = updated.valid;
  }

  updatedAdditionalFees(updated: { uiAdditionalFees: AdditionalFeeUiModel[]; valid: boolean }) {
    this.additionalFeeUiModels = updated.uiAdditionalFees;
    this.validStep[5] = updated.valid;
  }

  updatedCostSummary(updated: { quoteResponse: QuoteResponseModel; valid: boolean }) {
    this.updatedOptions(updated);
    this.validStep[6] = updated.valid;
  }

  updatedContingencies(contingencies: ContingencyModel[]) {
    if (this.quoteLinqVersion?.versionData) {
      this.quoteLinqVersion.versionData.contingencyVersions = contingencies;
    } else {
      this.quoteLinqVersion.versionData = {
        contingencyVersions: contingencies
      };
    }
  }

  stepInvalid(stepIndex: number) {
    return !this.validStep[stepIndex];
  }

  stepIsVisited(stepIndex: number) {
    return this.visitedStep[stepIndex];
  }

  navigate(event: StepperNavEvent) {
    for (let i = 0; i < event.endIndex; i++) {
      this.visitedStep[i] = true;
    }
  }

  addOption() {
    this.addingOption = true;
    // push to next ui cycle to allow an inprogress indicator to spin
    setTimeout(() => {
      // add a new option start with the status of either the other options or NEW
      const newQuoteResponseOption = {
        status:
          this.quoteResponse.quoteResponseOptions && this.quoteResponse.quoteResponseOptions.length > 0
            ? this.quoteResponse.quoteResponseOptions[0].status
            : QuoteResponseStatus.NEW
      };
      this.addOptionToLasers();
      this.addOptionToAdditionalFees();
      this.quoteResponse = this.updatedQuoteResponseModel(
        this.useImportData
          ? [...this.importData.quoteResponseOptions, newQuoteResponseOption]
          : [...this.quoteResponse.quoteResponseOptions, newQuoteResponseOption]
      );
      this.addingOption = false;
    });
  }

  cloneOption(index: number) {
    this.cloneLaserOption(index);
    this.cloneAdditionalFeeOption(index);
    this.quoteResponse = this.updatedQuoteResponseModel(
      this.useImportData
        ? [...this.importData.quoteResponseOptions, { ...this.importData.quoteResponseOptions[index] }]
        : [...this.quoteResponse.quoteResponseOptions, { ...this.quoteResponse.quoteResponseOptions[index] }]
    );
  }

  removeOption(index: number) {
    if (this.useImportData) {
      this.importData.quoteResponseOptions?.splice(index, 1);
    } else {
      this.quoteResponse.quoteResponseOptions?.splice(index, 1);
    }

    this.removeLaserOption(index);
    this.removeAdditionalFeeOption(index);
    this.quoteResponse = this.updatedQuoteResponseModel(
      this.useImportData ? [...this.importData.quoteResponseOptions] : [...this.quoteResponse.quoteResponseOptions]
    );
  }

  addLaser() {
    this.addingOption = true;
    setTimeout(() => {
      const newLaser = {
        options: Array(
          this.useImportData
            ? this.importData.quoteResponseOptions.length
            : this.quoteResponse.quoteResponseOptions.length
        ).fill({ excluded: false })
      };
      this.laserUiModels = [...this.laserUiModels, newLaser];
      this.addingOption = false;
    });
  }

  addAdditionalFee() {
    this.addingOption = true;
    setTimeout(() => {
      const newFee = {
        recurrence: FeeRecurrence.PEPM,
        options: Array(
          this.useImportData
            ? this.importData.quoteResponseOptions.length
            : this.quoteResponse.quoteResponseOptions.length
        ).fill({ name: '' })
      };
      this.additionalFeeUiModels = [...this.additionalFeeUiModels, newFee];
      this.addingOption = false;
    });
  }

  importProposalText() {
    if ((this.dyXmlEnabled || this.ocrTemplates?.length > 0 || this.rmtXmlEnabled) && !this.manualImportOnly) {
      this.dialogService
        .openDialog<ExtractTextDialogComponent>(
          ExtractTextDialogComponent,
          {
            templates: this.ocrTemplates,
            dyXmlEnabled: this.dyXmlEnabled,
            responseId: this.quoteResponse.id,
            rmtXmlEnabled: this.rmtXmlEnabled,
            version: this.quoteLinqVersion
          },
          {
            height: '550px',
            disableClose: true
          }
        )
        .afterClosed()
        .subscribe((res: ExtractFileModel) => {
          if (this.requirePdf && !res) {
            // extract modal was cancelled, cancel this one too since attaching a pdf is required
            this.dialogRef.close(null);
          } else if (!!res) {
            if (!res.extractText.extractedObj) {
              res.extractText.extractedObj = {};
            }
            if (Array.isArray(res.extractText.extractedObj.quoteResponseOptions)) {
              // When importing, apply the id, status, and createdDate of the existing options. If
              // an existing option doesn't exist, that means the imported option is NEW.
              res.extractText.extractedObj.quoteResponseOptions.forEach((opt, index) => {
                if (
                  !!this.quoteResponse.quoteResponseOptions &&
                  this.quoteResponse.quoteResponseOptions.length > index
                ) {
                  opt.id = this.quoteResponse.quoteResponseOptions[index].id;
                  opt.status = this.quoteResponse.quoteResponseOptions[index].status;
                  opt.createdDate = this.quoteResponse.quoteResponseOptions[index].createdDate;
                } else {
                  opt.status = QuoteResponseStatus.NEW;
                }
              });
            } else {
              res.extractText.extractedObj.quoteResponseOptions = [];
            }

            // put some original data back onto the extractedObj as they either won't be found or are highly unlikely to be found in the extraction
            res.extractText.extractedObj.id = this.quoteResponse.id;
            res.extractText.extractedObj.status = this.quoteResponse.status;
            res.extractText.extractedObj.type ||= this.quoteResponse.type;

            this.importData = res.extractText.extractedObj;
            this.ocrData = res.extractText.fields;

            if (!this.importData.enrollmentTier) {
              determineEnrollmentTier(this.importData, this.ocrData);
            }

            // reset the additional fees
            this.additionalFeeUiModels = this.translateToUiAdditionalFees(res.extractText.extractedObj);

            // reset the lasers
            this.laserUiModels = this.translateToUiLasers(res.extractText.extractedObj);
            this.useImportData = true;
            this.filesToSave = res.processedFiles;
            this.unsavedProposal = res.processedFiles?.find((f) => f.fileModel.docType === FileDocType.PROPOSAL);
            if (res.supportFileSelected) {
              this.quoteLinqVersion.rmtFile = res.supportFileSelected;
            } else {
              // clear the stored version's rmtFile, in the case of a re-import on a draft edit
              this.quoteLinqVersion.rmtFile = null;
            }
            switch (res.extractType) {
              case ExtractType.DY:
              case ExtractType.RMT_XML:
                this.importEventType = QuoteResponseEventType.RESPONSE_VERSION_CREATED_XML;
                break;
              case ExtractType.OCR:
                this.importEventType = QuoteResponseEventType.RESPONSE_VERSION_CREATED_OCR;
                break;
              default:
                this.importEventType = QuoteResponseEventType.RESPONSE_VERSION_CREATED_MANUAL;
            }
          }
        });
    } else {
      this.dialogService
        .openDialog(
          QuoteSupportingFilePdfDialogComponent,
          {
            quoteResponseId: this.quoteResponse.id,
            quoteLinqVersion: this.quoteLinqVersion
          },
          {
            disableClose: true
          },
          '40vw'
        )
        .afterClosed()
        .subscribe((res: QuoteSupportingFilePdfDialogReturn) => {
          if (this.requirePdf && !res) {
            // extract modal was cancelled, cancel this one too since attaching a pdf is required
            this.dialogRef.close(null);
          } else if (!!res) {
            this.importEventType = QuoteResponseEventType.RESPONSE_VERSION_CREATED_MANUAL;
            if (res.fileToUpload) {
              const fileExtModel = new FileExtModel();
              fileExtModel.file = res.fileToUpload;
              fileExtModel.fileModel = new FileModel();
              fileExtModel.fileModel.docType = FileDocType.PROPOSAL;
              fileExtModel.fileModel.name = res.fileToUpload.name;
              // clear the stored version's rmtFile, in the case of a re-import on a draft edit
              this.quoteLinqVersion.rmtFile = null;
              this.filesToSave = [fileExtModel];
              this.unsavedProposal = fileExtModel;
            } else if (res.supportingFile) {
              // user selected an already existing supporting file, just assign to the version
              this.quoteLinqVersion.rmtFile = res.supportingFile;
            }
          }
        });
    }
  }

  submit(action: string) {
    // ensure that import data gets used
    // it is possible that the local form data is never updated if the user saves without interacting with the form after an import
    this.quoteResponse = this.useImportData
      ? this.updatedQuoteResponseModel(this.importData.quoteResponseOptions, this.quoteResponse)
      : this.quoteResponse;

    // translate the laser models back to the entity model
    this.translateToQuoteResponse(this.laserUiModels);

    // translate the additional fees models back to the entity model
    this.translateAdditionalFeesToQuoteResponse(this.additionalFeeUiModels);

    const uploadSupportingFileCalls = [];
    this.filesToSave.forEach((fileToSave) => {
      uploadSupportingFileCalls.push(
        this.quoteResponseService.uploadSupportFiles(
          fileToSave.file,
          this.quoteResponse.id,
          fileToSave.fileModel.docType,
          false
        )
      );
    });

    // stuff happens a lot during import that removes certain fields since they really aren't generally found in the text extraction
    // try to put them back using the latest version, if available
    this.quoteResponse.type = this.quoteResponse.type || this.latestVersion?.versionData?.type;
    this.quoteResponse.laserConfirmation =
      this.quoteResponse.laserConfirmation || this.latestVersion?.versionData?.laserConfirmation;

    if (action === this.saveAction) {
      this.saving = true;
      forkJoin(uploadSupportingFileCalls)
        .pipe(
          defaultIfEmpty(null) // ensures the forkJoin gets to the subscribe if there weren't any files to upload
        )
        .subscribe((savedFiles: FileModel[]) => {
          // update status to UNDERWRITING if not already
          if (this.quoteResponse.status === QuoteResponseStatus.NEW) {
            this.quoteResponse.status = QuoteResponseStatus.UNDERWRITING;
          }

          // capture which file was saved, so we have that info and can clear the files to save
          this.savedProposalFile =
            savedFiles && !this.savedProposalFile ? savedFiles.find((f) => f.docType === FileDocType.PROPOSAL) : null;
          this.filesToSave.length = 0;

          if (this.quoteLinqVersion?.id > 0) {
            // update
            this.quoteLinqVersionService
              .update(
                this.quoteLinqVersion.versionId,
                this.quoteResponse,
                this.contingencies,
                this.savedProposalFile?.id || this.quoteLinqVersion?.rmtFile?.id,
                true,
                QuoteResponseEventType.RESPONSE_VERSION_UPDATED_MANUAL
              )
              .subscribe(
                (result) => {
                  this.notificationService.successfulNotification('Proposal updated.');
                  this.dialogRef.close(result);
                },
                () => {
                  this.saving = false;
                }
              );
          } else {
            // create
            this.quoteLinqVersionService
              .create(
                this.quoteResponse,
                this.contingencies,
                this.quoteLinqVersion?.rmtFile?.id || this.savedProposalFile?.id,
                true,
                this.importEventType
              )
              .subscribe(
                (result) => {
                  this.notificationService.successfulNotification('Proposal updated.');
                  this.dialogRef.close(result);
                },
                () => {
                  this.saving = false;
                }
              );
          }
        });
    } else {
      // open the submit confirmation modal, it will also take care of uploading any files needing to be saved
      this.dialogService
        .openDialog(
          QuoteResponseSubmitComponent,
          {
            quoteResponse: this.quoteResponse,
            contingencies: this.contingencies,
            filesToSave: this.filesToSave,
            version: this.quoteLinqVersion,
            importEvent: this.importEventType
          },
          { disableClose: true },
          '60vw'
        )
        .afterClosed()
        .subscribe((submitDialogResponse) => {
          if (submitDialogResponse) {
            this.notificationService.successfulNotification('Proposal updated.');
            this.dialogRef.close(submitDialogResponse);
          }
        });
    }
  }

  private updatedOptions(updated: { quoteResponse: QuoteResponseModel; valid: boolean }) {
    const newQuoteResponseOptions: QuoteResponseOption[] = [];
    updated.quoteResponse.quoteResponseOptions.forEach((opt, index) => {
      const originalOpt = this.quoteResponse.quoteResponseOptions?.[index] || {};
      newQuoteResponseOptions.push({
        ...originalOpt,
        ...opt
      });
    });
    this.quoteResponse = this.updatedQuoteResponseModel(newQuoteResponseOptions);
  }

  // creates a new quote response model based on updated options, general info update while keeping track if import data should be used
  private updatedQuoteResponseModel(
    options: QuoteResponseOption[],
    generalUpdatedModel: QuoteResponseModel = {}
  ): QuoteResponseModel {
    let updated;
    if (this.useImportData) {
      updated = {
        ...this.quoteResponse,
        ...this.importData,
        ...generalUpdatedModel,
        quoteResponseOptions: options
      };
    } else {
      updated = {
        ...this.quoteResponse,
        ...generalUpdatedModel,
        quoteResponseOptions: options
      };
    }
    this.useImportData = false;
    return this.quoteResponseService.cleanQuoteResponseModel(updated);
  }

  private addOptionToLasers(): void {
    const newLasers = [...this.laserUiModels];
    newLasers.forEach((uiLaser) => {
      uiLaser.options.push({ excluded: false });
    });
    this.laserUiModels = newLasers;
  }

  private cloneLaserOption(index: number): void {
    const newLasers = [...this.laserUiModels];
    newLasers.forEach((uiLaser) => {
      uiLaser.options.splice(index, 0, { ...uiLaser.options[index] });
    });
    this.laserUiModels = newLasers;
  }

  private removeLaserOption(index: number): void {
    const newLasers = [...this.laserUiModels];
    newLasers.forEach((uiLaser) => {
      uiLaser.options.splice(index, 1);
    });
    this.laserUiModels = newLasers;
  }

  private translateToUiLasers(quoteResponse: QuoteResponseModel): LaserUiModel[] {
    const uiModels: LaserUiModel[] = [];

    quoteResponse.quoteResponseOptions?.forEach((option, index) => {
      option.lasers?.forEach((optionLaser) => {
        const uiLaser = uiModels.find((uiModel) => uiModel.name === optionLaser.name);
        if (uiLaser) {
          uiLaser.options[index] = optionLaser;
          // If an option is excluded then it will mark itself as conditional = false, so OR all the conditionals together just in case one laser is conditional
          uiLaser.conditional = uiLaser.conditional || optionLaser.conditional;
        } else {
          const newUiLaser = new LaserUiModel(optionLaser.name, optionLaser.relationship);
          newUiLaser.condition = optionLaser.condition;
          newUiLaser.conditional = optionLaser.conditional;
          // fill up the options such that each name has a model for each option (even if there isn't one stored in the real quote response option)
          // so the laser-array component's form will display the option columns correctly
          newUiLaser.options = Array(quoteResponse.quoteResponseOptions.length).fill({
            name: optionLaser.name,
            relationship: optionLaser.relationship,
            condition: optionLaser.condition,
            conditional: optionLaser.conditional
          });
          newUiLaser.options[index] = optionLaser;
          uiModels.push(newUiLaser);
        }
      });
    });

    return uiModels;
  }

  private translateToQuoteResponse(uiModels: LaserUiModel[]): QuoteResponseModel {
    this.quoteResponse.quoteResponseOptions?.forEach((option, qrOptionIndex) => {
      const lasers = [];
      uiModels.forEach((uiModel) => {
        // weed out any option that doesn't have any fields filled out
        // because they were added to appease the form and get option columns to show up correctly
        if (uiModel.options && uiModel.options[qrOptionIndex] && this.laserNotEmpty(uiModel.options[qrOptionIndex])) {
          uiModel.options[qrOptionIndex].name = uiModel.name;
          uiModel.options[qrOptionIndex].relationship = uiModel.relationship;
          uiModel.options[qrOptionIndex].condition = uiModel.condition;
          uiModel.options[qrOptionIndex].conditional = uiModel.options[qrOptionIndex].excluded
            ? false
            : uiModel.conditional;
          lasers.push(uiModel.options[qrOptionIndex]);
        }
      });
      option.lasers = lasers;
    });

    return this.quoteResponse;
  }

  private laserNotEmpty(laser: LaserModel): boolean {
    return (
      !!laser &&
      (!!laser.specDeductible ||
        !!laser.contractBasis ||
        !!laser.maxReimbursement ||
        !!laser.condition ||
        laser.excluded)
    );
  }

  private addOptionToAdditionalFees(): void {
    const newFees = [...this.additionalFeeUiModels];
    newFees.forEach((uiFee) => {
      uiFee.options.push({ name: '' });
    });
    this.additionalFeeUiModels = newFees;
  }

  private cloneAdditionalFeeOption(index: number): void {
    const newFees = [...this.additionalFeeUiModels];
    newFees.forEach((uiFee) => {
      uiFee.options.splice(index, 0, { ...uiFee.options[index] });
    });
    this.additionalFeeUiModels = newFees;
  }

  private removeAdditionalFeeOption(index: number): void {
    const newFees = [...this.additionalFeeUiModels];
    newFees.forEach((uiFee) => {
      uiFee.options.splice(index, 1);
    });
    this.additionalFeeUiModels = newFees;
  }

  private translateToUiAdditionalFees(quoteResponse: QuoteResponseModel): AdditionalFeeUiModel[] {
    const uiModels: AdditionalFeeUiModel[] = [];

    quoteResponse.quoteResponseOptions?.forEach((option, index) => {
      option.additionalFees?.forEach((optionFee) => {
        const uiFee = uiModels.find((uiModel) => uiModel.name === optionFee.name);
        if (uiFee) {
          uiFee.options[index] = optionFee;
        } else {
          const newUiFee = new AdditionalFeeUiModel(optionFee.name, optionFee.recurrence);
          newUiFee.type = optionFee.type;
          // fill up the options such that each name has a model for each option (even if there isn't one stored in the real quote response option)
          // so the additional-fee-array component's form will display the option columns correctly
          newUiFee.options = Array(quoteResponse.quoteResponseOptions.length).fill({
            name: optionFee.name,
            recurrence: optionFee.recurrence,
            type: optionFee.type,
            amount: optionFee.amount
          });
          newUiFee.options[index] = optionFee;
          uiModels.push(newUiFee);
        }
      });
    });

    return uiModels;
  }

  private translateAdditionalFeesToQuoteResponse(uiModels: AdditionalFeeUiModel[]): QuoteResponseModel {
    this.quoteResponse.quoteResponseOptions?.forEach((option, qrOptionIndex) => {
      const fees = [];
      uiModels.forEach((uiModel) => {
        // weed out any option that doesn't have any fields filled out
        // because they were added to appease the form and get option columns to show up correctly
        if (
          uiModel.options &&
          uiModel.options[qrOptionIndex] &&
          this.additionalFeeNotEmpty(uiModel.options[qrOptionIndex])
        ) {
          uiModel.options[qrOptionIndex].name = uiModel.name;
          uiModel.options[qrOptionIndex].recurrence = uiModel.recurrence;
          uiModel.options[qrOptionIndex].type = uiModel.type;
          fees.push(uiModel.options[qrOptionIndex]);
        }
      });
      option.additionalFees = fees;
    });

    return this.quoteResponse;
  }

  private additionalFeeNotEmpty(fee: AdditionalFeeModel): boolean {
    return !!fee && !!fee.amount;
  }

  displayVersionProposal() {
    if (this.quoteLinqVersion.rmtFile) {
      let params = new HttpParams();
      this.httpService
        .downloadFileWithoutOrg(
          { path: `quote-response/${this.quoteResponse.id}/supportfile/${this.quoteLinqVersion.rmtFile.id}/stream` },
          { params: params, fileName: null }
        )
        .subscribe((blob) => {
          this.quoteLinqVersionService.openVersionFileDialog(blob, this.quoteLinqVersion.versionId);
        });
    }
  }

  displayLoadedFile() {
    if (this.unsavedProposal) {
      this.quoteLinqVersionService.openVersionFileDialog(this.unsavedProposal.file, this.quoteLinqVersion.versionId);
    }
  }

  addContingency(): void {
    if (this.contingencies) {
      this.contingencies.push({
        contingencyText: ''
      });
    } else {
      this.contingencies = [
        {
          contingencyText: ''
        }
      ];
    }
  }

  loadData(
    loadFunction: () => Observable<
      [QuoteResponseModel, OcrTemplateModel[], QuoteLinqVersionModel, boolean, QuoteLinqVersionModel]
    >
  ): Observable<[QuoteResponseModel, OcrTemplateModel[], QuoteLinqVersionModel, boolean, QuoteLinqVersionModel]> {
    return loadFunction().pipe(
      tap(
        ([quoteResponse, ocrTemplates, version, submitOnly, latestVersion]: [
          QuoteResponseModel,
          OcrTemplateModel[],
          QuoteLinqVersionModel,
          boolean,
          QuoteLinqVersionModel
        ]) => {
          this.overrideLoading = true;
          this.quoteResponse = quoteResponse || new QuoteResponseModel();
          this.quoteResponse.type ||= QuoteResponseType.ILLUSTRATIVE;

          if (!this.quoteResponse.quoteResponseOptions?.length) {
            this.quoteResponse.quoteResponseOptions = [new QuoteResponseOption()];
          }
          this.readOnly = READ_ONLY_STATUSES.includes(this.quoteResponse.status);
          // if the responding org has dyXmlEnabled, show the option
          this.dyXmlEnabled = this.quoteResponse.org.features.includes(FeatureType.dyXmlEnabled);
          this.rmtXmlEnabled = this.quoteResponse.org.features.includes(FeatureType.responseXmlEnabled);
          this.ocrTemplates = ocrTemplates;
          this.laserUiModels = this.translateToUiLasers(this.quoteResponse);
          this.additionalFeeUiModels = this.translateToUiAdditionalFees(this.quoteResponse);
          this.latestVersion = latestVersion;

          this.quoteLinqVersion = version || latestVersion;
          this.isLatestVersion =
            (version && !version?.id) ||
            (!!latestVersion && !version) ||
            version?.versionId === latestVersion?.versionId;
          this.contingencies = this.quoteLinqVersion?.id
            ? this.quoteLinqVersion?.versionData?.contingencyVersions?.length
              ? this.quoteLinqVersion?.versionData?.contingencyVersions
              : [{ contingencyText: '' }]
            : latestVersion?.id
              ? latestVersion.versionData?.contingencyVersions
              : [{ contingencyText: '' }];
          this.requirePdf = !this.quoteLinqVersion.rmtFile && !this.readOnly;

          if (this.quoteResponse.status === QuoteResponseStatus.SUBMITTED && this.quoteLinqVersion.id > 0) {
            this.submitAction = `Update Version ${this.quoteLinqVersion.versionId}`;
          } else {
            this.submitAction = `Submit Version ${this.quoteLinqVersion.versionId}`;
          }
          this.saveAction = `Save Version ${this.quoteLinqVersion.versionId} as Draft`;
          // if we have a version without a rmtFile, i.e. during migration
          // then only allow the manual upload regardless of the org's import capabilities when not in NEW or UNDERWRITING
          this.manualImportOnly =
            !(
              this.quoteLinqVersion.id > 0 &&
              !this.quoteLinqVersion.rmtFile &&
              (this.quoteResponse.status === QuoteResponseStatus.NEW ||
                this.quoteResponse.status === QuoteResponseStatus.UNDERWRITING)
            ) &&
            this.quoteLinqVersion.id > 0 &&
            !this.quoteLinqVersion.rmtFile;

          this.stepperActions =
            (this.quoteResponse.status === QuoteResponseStatus.NEW ||
              this.quoteResponse.status === QuoteResponseStatus.UNDERWRITING) &&
            this.quoteLinqVersion.versionStatus === QuoteLinqVersionStatusType.DRAFT &&
            !submitOnly
              ? [this.submitAction, this.saveAction]
              : [this.submitAction];

          this.loading = false;

          if (this.requirePdf) {
            setTimeout(() => {
              this.importProposalText();
            });
          }
        }
      )
    );
  }
}
