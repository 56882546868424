import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ContingencyModel } from '@quote/quote-response/quote-response-stepper/contingencies/contingency.model';
import { faGripDots, faX } from '@fortawesome/pro-solid-svg-icons';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { FileExtModel, FileModel } from '@file-upload-lib/file.model';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpResourceService } from '@main/http-resource.service';

@Component({
  selector: 'app-contingencies',
  templateUrl: './contingencies.component.html',
  styleUrls: ['./contingencies.component.scss']
})
export class ContingenciesComponent implements OnChanges, OnDestroy {
  @Input() quoteResponseId: number;
  @Input() contingencies: ContingencyModel[];
  @Input() versionFile: FileModel;
  @Input() unsavedFile: FileExtModel;
  @Input() readonly: boolean;
  @Input() isLatestVersion: boolean;
  @Output() update = new EventEmitter<ContingencyModel[]>();
  blob: Blob;
  faGripDots = faGripDots;
  deleteIcon = faX;
  subs: Subscription = new Subscription();

  constructor(
    private dialogsService: DialogsService,
    private httpService: HttpResourceService
  ) {}

  ngOnChanges({ contingencies, versionFile, unsavedFile }: SimpleChanges): void {
    if (contingencies?.currentValue) {
      this.contingencies = contingencies.currentValue;
    }

    if (versionFile?.currentValue || unsavedFile?.currentValue) {
      this.getFileBlob();
    }
  }

  getFileBlob() {
    // Wipe out the last blob and settimeout to force the pdf viewer to rerender. This only applies when new files are uploaded.
    this.blob = undefined;
    if (this.versionFile) {
      let params = new HttpParams();
      this.subs.add(
        this.httpService
          .downloadFileWithoutOrg(
            { path: `quote-response/${this.quoteResponseId}/supportfile/${this.versionFile.id}/stream` },
            { params: params, fileName: null }
          )
          .subscribe((blob) => setTimeout(() => (this.blob = blob)))
      );
    } else {
      setTimeout(() => (this.blob = this.unsavedFile.file));
    }
  }

  doUpdate(): void {
    this.update.emit(this.contingencies);
  }

  canEdit(): boolean {
    return !this.readonly && this.isLatestVersion;
  }

  removeContingency(contingency: ContingencyModel): void {
    this.dialogsService
      .confirmationDialog({
        label: 'Delete Contingency',
        message: 'Contingency will be permanently deleted.',
        actionText: 'Delete'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          const index = this.contingencies.indexOf(contingency);
          if (index >= 0) {
            this.contingencies.splice(index, 1);
          }
          this.update.emit(this.contingencies);
        }
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.contingencies, event.previousIndex, event.currentIndex);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
