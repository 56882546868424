import { UserModel } from '@auth/models/user.model';
import { QuoteResponseStatus } from '@common/models/quote-response.model';

export enum QuoteResponseEventType {
  CONTINGENCIES_UPDATED = 'CONTINGENCIES_UPDATED',
  DISCLOSURES_UPDATED = 'DISCLOSURES_UPDATED',
  PARTICIPANTS_UPDATED = 'PARTICIPANTS_UPDATED',
  PLANDESIGN_UPDATED = 'PLANDESIGN_UPDATED',
  MESSAGE_SENT = 'MESSAGE_SENT',
  REQUEST_SENT_TO_ESL = 'REQUEST_SENT_TO_ESL',
  REQUEST_UPDATED = 'REQUEST_UPDATED',
  REQUEST_SUPPORTFILE_ADDED = 'REQUEST_SUPPORTFILE_ADDED',
  REQUEST_SUPPORTFILE_REMOVED = 'REQUEST_SUPPORTFILE_REMOVED',
  REQUEST_SUPPORTFILE_UPDATED = 'REQUEST_SUPPORTFILE_UPDATED',
  RESPONSE_CREATED = 'RESPONSE_CREATED',
  RESPONSE_UPDATED = 'RESPONSE_UPDATED',
  RESPONSE_STATE_CHANGED = 'RESPONSE_STATE_CHANGED',
  RESPONSE_SUPPORTFILE_ADDED = 'RESPONSE_SUPPORTFILE_ADDED',
  RESPONSE_SUPPORTFILE_REMOVED = 'RESPONSE_SUPPORTFILE_REMOVED',
  RESPONSE_SUPPORTFILE_UPDATED = 'RESPONSE_SUPPORTFILE_UPDATED',
  RESPONSE_UPDATED_ESL = 'RESPONSE_UPDATED_ESL',
  RESPONSE_VERSION_CREATED_EXT = 'RESPONSE_VERSION_CREATED_EXT',
  RESPONSE_VERSION_CREATED_MANUAL = 'RESPONSE_VERSION_CREATED_MANUAL',
  RESPONSE_VERSION_CREATED_OCR = 'RESPONSE_VERSION_CREATED_OCR',
  RESPONSE_VERSION_CREATED_XML = 'RESPONSE_VERSION_CREATED_XML',
  RESPONSE_VERSION_UPDATED_EXT = 'RESPONSE_VERSION_UPDATED_EXT',
  RESPONSE_VERSION_UPDATED_MANUAL = 'RESPONSE_VERSION_UPDATED_MANUAL',
  RESPONSE_VERSION_UPDATED_OCR = 'RESPONSE_VERSION_UPDATED_OCR',
  RESPONSE_VERSION_UPDATED_XML = 'RESPONSE_VERSION_UPDATED_XML',
  RESPONSE_VERSION_DELETED = 'RESPONSE_VERSION_DELETED'
}

export class QuoteResponseEventModel {
  constructor(
    public id?: number,
    public quoteResponseId?: number,
    public user?: UserModel,
    public createdDate?: any,
    public event?: QuoteResponseEventType,
    public eventObjectId?: any,
    public responseStatus?: QuoteResponseStatus,
    public supportingInfo?: object,
    public quoteLinqVersion?: number
  ) {}
}
