import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { appRoutes } from '@app/app.routing';
import { AuthState } from '@auth/store/auth.state';
import { AppVersionInterceptor } from '@common/app-version.interceptor';
import { DialogsModule } from '@common/dialogs/dialogs.module';
import { FileUploadDialogComponent } from '@common/dialogs/file-upload-dialog/file-upload-dialog.component';
import { HttpErrorInterceptor } from '@common/http-error.interceptor';
import { AccountAsyncOptionGroupService } from '@common/services/account-async-option-group.service';
import { AccountContactAsyncOptionGroupService } from '@common/services/account-contact-async-option-group.service';
import { AccountOptionService } from '@common/services/account-option.service';
import { AccountService } from '@common/services/account.service';
import { RequestOrgByMembershipOptionService } from '@common/services/request-org-by-membership-option.service';
import { OrgAsyncOptionGroupService } from '@common/services/org-async-option-group.service';
import { OrgMemberOptionService } from '@common/services/org-member-option.service';
import { OrgMemberService } from '@common/services/org-member.service';
import { PolicyAsyncOptionGroupService } from '@common/services/policy-async-option-group.service';
import { PolicyOrgMemberOptionService } from '@common/services/policy-org-member-option.service';
import { PorOptionsService } from '@common/services/por-options.service';
import { PorService } from '@common/services/por.service';
import { QuoteRequestService } from '@common/services/quote-request.service';
import { ReportTypeRecurrenceAsyncOptionGroupService } from '@common/services/report-type-recurrence-async-option-group.service';
import { RfpAsyncOptionGroupService } from '@common/services/rfp-async-option-group.service';
import { UnderWriterDataService } from '@common/services/under-writer-data.service';
import { UnderwriterOptionService } from '@common/services/underwriter-option-service.service';
import { UnderwriterOrgOptionService } from '@common/services/underwriter-org-option.service';
import { UserOrgMembershipOptionService } from '@common/services/user-org-membership-option.service';
import { IcdCodeOptionService } from '@configuration/admin/icd-code-list/icd-code-option.service';
import { IcdCodeService } from '@configuration/admin/icd-code-list/icd-code.service';
import { SicCodeOptionService } from '@configuration/admin/sic-code/sic-code-option.service';
import { SicCodeService } from '@configuration/admin/sic-code/sic-code.service';
import { TagOptionService } from '@configuration/admin/tag-list/tag-option.service';
import { TagService } from '@configuration/admin/tag-list/tag.service';
import { DYNAMIC_VALIDATORS } from '@form-lib/models/validators.model';
import { ASYNC_OPTIONS } from '@form-lib/options/options.model';
import { EndpointConnectionOptionService } from '@main/org-endpoint/service/org-endpoint-connection-option.service';
import { EndpointProducerOptionService } from '@main/org-endpoint/service/org-endpoint-producer-option.service';
import { EndpointService } from '@main/org-endpoint/service/org-endpoint.service';
import { OrgOcrConfigService } from '@main/org-ocr-config/service/org-ocr-config.service';
import { OrgOcrConfigValidators } from '@main/org-ocr-config/validators/org-ocr-config.validators';
import { OrgHttpService } from '@main/org/org-http.service';
import { OrgOptionService } from '@main/org/org-option.service';
import { SellSideOptionService } from '@main/org/sell-side-option.service';
import { OrgValidators } from '@main/org/validators/org.validators';
import { OrgMembershipState } from '@main/store/org-membership/org-membership.state';
import { SiteFilterState } from '@main/store/site-filter/site-filter.state';
import { UserState } from '@main/store/user/user.state';
import { WorkspaceState } from '@main/store/workspace/workspace.state';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { QueryDisplayComponent } from '@shared/components/query-display/query-display.component';
import { SharedModule } from '@shared/shared.module';
import { PolicyHttpService } from '@smart/component/policy/service/policy-http.service';
import { PolicyOptionService } from '@smart/component/policy/service/policy-option.service';
import { PolicyTierOptionService } from '@smart/component/policy/service/policy-tier-option.service';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { AccountOrgOptionService } from '@common/services/account-org-option.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PreferredAccountOptionService } from '@common/services/preferred-account-option.service';
import { PreferredAccountsService } from '@quote/org-preferred-accounts/preferred-accounts.service';
import { AccountModelOptionService } from '@common/services/account-model-option.service';
import { CredPbmOrgModelOptionService } from '@common/services/cred-pbm-org-model-option.service';
import { GlobalTpaOptionService } from '@common/services/global-tpa-model-option.service';
import { GlobalTpaService } from '@common/services/global-tpa.service';
import { PbmOrgOptionService } from '@common/services/pbm-org-option.service';
import { RapidQuoteRxProfileOptionService } from '@main/org/rapid-quote-rx-profile-option.service';
import { RapidQuoteRxProfileService } from '@common/services/rapid-quote-rx-profile.service';
import { AuthInterceptor } from '@auth/auth.interceptor';
import { DataTableState } from '@main/store/data-table/data-table.state';
import { RxContractOptionService } from '@common/services/rx-contract-model-option.service';
import { RxContractService } from '@common/services/rx-contract.service';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {}),
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
    DialogsModule,
    FontAwesomeModule,
    NgxsModule.forRoot([DataTableState, UserState, AuthState, OrgMembershipState, SiteFilterState, WorkspaceState], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        AuthState,
        'siteFilters.orgFunction',
        'siteFilters.orgMembership',
        'smart.siteFilters.orgMembership',
        'quote.siteFilters.orgMembership',
        'configuration.siteFilters.orgMembership'
      ]
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      actionSanitizer: (action) => {
        const isRouterAction = action.type.startsWith('[Router]');
        return isRouterAction
          ? {
              ...action,
              routerState: { path: action.path },
              event: {
                ...action.event,
                state: '<<LONG_BLOB>>'
              }
            }
          : action;
      },
      stateSanitizer: (state) => (state.router ? { ...state, router: '<<LONG_BLOB>>' } : state)
    })
  ],
  declarations: [AppComponent, QueryDisplayComponent, FileUploadDialogComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
      deps: [Injector]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [Injector]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: DYNAMIC_VALIDATORS,
      useClass: OrgValidators,
      multi: true,
      deps: [OrgHttpService, OrgMemberService]
    },
    {
      provide: DYNAMIC_VALIDATORS,
      useClass: OrgOcrConfigValidators,
      multi: true,
      deps: [OrgOcrConfigService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: SicCodeOptionService,
      multi: true,
      deps: [SicCodeService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: SellSideOptionService,
      multi: true,
      deps: [OrgHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: OrgOptionService,
      multi: true,
      deps: [OrgHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: IcdCodeOptionService,
      multi: true,
      deps: [IcdCodeService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: AccountOptionService,
      multi: true,
      deps: [AccountService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: AccountModelOptionService,
      multi: true,
      deps: [AccountService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: AccountOrgOptionService,
      multi: true,
      deps: [AccountService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: CredPbmOrgModelOptionService,
      multi: true,
      deps: [OrgHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: GlobalTpaOptionService,
      multi: true,
      deps: [GlobalTpaService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PolicyOrgMemberOptionService,
      multi: true,
      deps: [OrgMemberService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: OrgMemberOptionService,
      multi: true,
      deps: [OrgMemberService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: UserOrgMembershipOptionService,
      multi: true,
      deps: [Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PolicyOptionService,
      multi: true,
      deps: [PolicyHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PolicyTierOptionService,
      multi: true,
      deps: [PolicyHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: UnderwriterOrgOptionService,
      multi: true,
      deps: [OrgHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: UnderwriterOptionService,
      multi: true,
      deps: [UnderWriterDataService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: EndpointConnectionOptionService,
      multi: true,
      deps: [EndpointService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: EndpointProducerOptionService,
      multi: true,
      deps: [EndpointService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PorOptionsService,
      multi: true,
      deps: [PorService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: RequestOrgByMembershipOptionService,
      multi: true,
      deps: [Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: TagOptionService,
      multi: true,
      deps: [TagService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: OrgAsyncOptionGroupService,
      multi: true,
      deps: [OrgMemberService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: AccountContactAsyncOptionGroupService,
      multi: true,
      deps: [AccountService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: AccountAsyncOptionGroupService,
      multi: true,
      deps: [AccountService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: RfpAsyncOptionGroupService,
      multi: true,
      deps: [QuoteRequestService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PolicyAsyncOptionGroupService,
      multi: true,
      deps: [PolicyHttpService, Store]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: ReportTypeRecurrenceAsyncOptionGroupService,
      multi: true,
      deps: []
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PreferredAccountOptionService,
      multi: true,
      deps: [PreferredAccountsService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: PbmOrgOptionService,
      multi: true,
      deps: [OrgHttpService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: RapidQuoteRxProfileOptionService,
      multi: true,
      deps: [RapidQuoteRxProfileService]
    },
    {
      provide: ASYNC_OPTIONS,
      useClass: RxContractOptionService,
      multi: true,
      deps: [RxContractService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
