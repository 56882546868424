import { OrgFunction } from '@common/models/org-type.model';
import { FormModel } from '@form-lib/models/form.model';
import { FeatureType } from '@main/org/models/org.model';

export enum QuoteTypeProduct {
  RX = 'RX',
  STOP_LOSS = 'STOP_LOSS'
}

export class QuoteTypeModel {
  id?: number;
  name?: string;
  icon?: string;
  eslOfficeEnabled?: boolean;
  active?: boolean;
  reqFormLayout?: FormModel;
  respFormLayout?: FormModel;
  responseFields?: Array<string>;
  orgId?: number;
  orgName?: string;
  orgFeatures?: FeatureType[];
  orgFunction?: OrgFunction;
  proposalTemplateRmtFile?: any;
  product?: QuoteTypeProduct;
  quoteTypeFields?: QuoteTypeFieldModel[];
}

export class QuoteTypeMinModel {
  id?: number;
  name?: string;
  icon?: string;
  orgId?: number;
  orgName?: string;
  orgFeatures?: FeatureType[];
  orgFunction?: OrgFunction;
  product?: QuoteTypeProduct;
  quoteTypeFields?: QuoteTypeFieldModel[];
}

export class QuoteTypeFieldModel {
  id?: number;
  quoteTypeId?: number;
  name?: string;
  type?: QuoteTypeFieldType;
  location?: QuoteTypeFieldLocation;
  groupName?: string;
  orderIndex?: number;
  inputSize?: number;
  maxInputSize?: number;
  minInputSize?: number;
  required?: boolean;
  defaultValues?: string[];

  constructor(
    quoteTypeId: number,
    name: string,
    type: QuoteTypeFieldType,
    location: QuoteTypeFieldLocation,
    groupName: string,
    inputSize: number,
    maxInputSize: number,
    minInputSize: number,
    required: boolean,
    defaultValues: string[]
  ) {
    this.type = type;
    this.name = name;
    this.type = type;
    this.location = location;
    this.groupName = groupName;
    this.inputSize = inputSize;
    this.maxInputSize = maxInputSize;
    this.minInputSize = minInputSize;
    this.required = required;
    this.defaultValues = defaultValues;
  }
}

export class QuoteTypeFieldUIModel extends QuoteTypeFieldModel {
  collectComments?: boolean;
  defaultValueToggle?: boolean;
  defaultValueText?: string;

  constructor(
    collectComments: boolean,
    quoteTypeId: number,
    name: string,
    type: QuoteTypeFieldType,
    location: QuoteTypeFieldLocation,
    groupName: string,
    inputSize: number,
    maxInputSize: number,
    minInputSize: number,
    required: boolean,
    defaultValues: string[]
  ) {
    super(quoteTypeId, name, type, location, groupName, inputSize, maxInputSize, minInputSize, required, defaultValues);
    this.collectComments = collectComments;
  }
}

export class QuoteTypeFieldDataModel {
  id?: number;
  quoteTypeField?: QuoteTypeFieldModel;
  quoteRequestId?: number;
  quoteResponseOptionId?: number;
  rxContractId?: number;
  values?: string[];
}

export class QuoteTypeFieldDataUIModel extends QuoteTypeFieldDataModel {
  value?: any;
  comment?: string;
}

export enum QuoteTypeFieldType {
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  YES_NO_COMMENTS = 'YES_NO_COMMENTS'
}

export enum QuoteTypeFieldLocation {
  QUOTE_REQUEST = 'QUOTE_REQUEST',
  QUOTE_RESPONSE_OPTION = 'QUOTE_RESPONSE_OPTION',
  // UI Only option
  QUOTE_REQUEST_AND_QUOTE_RESPONSE_OPTION = 'QUOTE_REQUEST_AND_QUOTE_RESPONSE_OPTION'
}
