import { UrlMatcher, UrlSegmentGroup, UrlTree } from '@angular/router';
import { isObject } from '@app/tools/object';

export class QueryParamModel {
  [k: string]: any;
}
export function encodeQueryParam(queryParams: QueryParamModel): QueryParamModel {
  const newQueryParam = { ...queryParams };
  Object.keys(queryParams).forEach((key) => {
    const params = queryParams[key];
    if (Array.isArray(params)) {
      newQueryParam[key] = params.map((param) => stringifyJson(param));
    } else if (isObject(params)) {
      newQueryParam[key] = stringifyJson(params);
    }
  });
  return newQueryParam;
}

export function decodeQueryParam(queryParams: QueryParamModel): QueryParamModel {
  return Object.keys(queryParams).reduce((acc, key) => {
    acc[key] = parseJson(queryParams[key]);
    return acc;
  }, {});
}

function stringifyJson(val) {
  return isObject(val) ? JSON.stringify(val) : val;
}

export function stringifyParams(linkParams) {
  if (!!linkParams) {
    const params = {};
    Object.keys(linkParams).forEach((key) => {
      params[key] = JSON.stringify(linkParams[key]);
    });
    return params;
  }
}

function parseJson(val) {
  try {
    return JSON.parse(val);
  } catch (e) {
    // not all url parameters are parsable JSON objects, just return it as a string
  }
  return val ? `${val}` : null;
}

export function segmentUrl({ root, queryParams }: UrlTree): { url: string; queryParams?: any } {
  const url = root.children.primary.segments.map((it) => it.path).join('/');
  return { url, queryParams };
}

export function generateHasIdParamMatcher(parentPath: string): UrlMatcher {
  return (url, group: UrlSegmentGroup) => {
    const lastSegment = group.segments[group.segments.length - 1];
    if (lastSegment?.path === '') return null;
    if (lastSegment?.path === parentPath) return null;
    return { consumed: url };
  };
}
