import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuoteResponseService } from '@common/services/quote-response.service';
import { OcrTemplateModel } from '@main/org-ocr-config/model/org-ocr-config.model';
import {
  ExtractAuditModel,
  ExtractAuditStatus,
  ExtractFileModel,
  ExtractType
} from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { ExtractTextService } from '@common/components/quote/extract-text-dialog/service/extract-text.service';
import { Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from '@shared/notifications/notification.service';
import { FileDocType, FileExtModel, FileModel } from '@file-upload-lib/file.model';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-ocr-extract',
  template: `
    <div class="rmt-card" *ngIf="templates?.length > 0" [appInProgressIndicator]="processing | async">
      <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
      A PDF of your proposal is required to continue.
      <app-file-upload labelText="Add PDF File" [multi]="false" (selectFile)="onSelectFile($event)"></app-file-upload>
      <div class="pt-3">
        <form [formGroup]="form" class="collapsible-form dense ocr-import-form">
          <mat-form-field>
            <mat-label>Select from Supporting Files</mat-label>
            <mat-select formControlName="fileId">
              <mat-option> -- </mat-option>
              <mat-option *ngFor="let item of supportFiles$ | async" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="pt-3">
            <mat-label>Select Template</mat-label>
            <mat-select formControlName="templateId">
              <mat-option
                *ngFor="let item of templates"
                [value]="item.id"
                [matTooltip]="itemDisplay(item)"
                matTooltipClass="single-line-tool-tip"
                matTooltipPosition="above"
              >
                {{ itemDisplay(item) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div *ngIf="templates?.length === 0">
      <p class="color-warn">
        <strong>No Templates Configured:</strong>
      </p>
      <p>You organization currently has no OCR templates. Contact support to create them.</p>
    </div>
  `,
  styleUrls: ['./ocr-extract.component.scss']
})
export class OcrExtractComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    fileId: new UntypedFormControl(null, Validators.required),
    templateId: new UntypedFormControl(null, Validators.required)
  });

  supportFiles$;
  supportFileList = [];
  supportFileEvents = {};
  tmpFileId = 0;

  infoIcon: IconDefinition = faInfoCircle;

  subs = new Subscription();

  @Input() responseId: number;
  @Input() templates: OcrTemplateModel[];
  @Output() valid = new EventEmitter<boolean>();
  @Output() processing = new EventEmitter<boolean>();
  @Output() extractedResponse = new EventEmitter<ExtractFileModel>();

  autoRefresh: Timeout;

  processingFile: File;

  constructor(
    private quoteResponseService: QuoteResponseService,
    private extractTextService: ExtractTextService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.responseId).pipe(
      map((rmtFiles) => rmtFiles.filter((rmtFile) => !rmtFile.versionId)),
      tap((result) => (this.supportFileList = result))
    );
    this.valid.emit(this.form.valid);
    this.subs.add(this.form.valueChanges.subscribe(() => this.valid.emit(this.form.valid)));
    if (this.templates.length === 1) {
      this.form.get('templateId').setValue(this.templates[0].id);
    }
  }

  onSelectFile(event) {
    const id = --this.tmpFileId;
    this.supportFileList.push({ name: event.name, id: id });
    this.supportFileEvents[id] = event;
    this.form.get('fileId').setValue(id);

    this.supportFiles$ = of(this.supportFileList);
  }

  requestOcr() {
    if (this.form.invalid) {
      this.form.markAsDirty();
      this.form.markAsTouched();
      return;
    }

    this.processing.emit(true);
    const { templateId, fileId } = this.form.value;

    if (fileId < 0) {
      const file = this.supportFileEvents[fileId];
      this.getSupportFileText(null, templateId, file).subscribe((result) => {
        this.processingFile = file;
        this.processResult(result);
      });
    } else {
      this.getSupportFileText(fileId, templateId, null).subscribe((result) => {
        this.processResult(result);
      });
    }
  }

  cancelAutoRefresh() {
    if (!!this.autoRefresh) {
      clearTimeout(this.autoRefresh);
    }
  }

  startAutoRefresh(extractAuditId: number) {
    this.cancelAutoRefresh();
    // a cast that is necessary for testing harness to work
    this.autoRefresh = setTimeout((_) => this.checkResult(extractAuditId), 5000) as unknown as Timeout;
  }

  checkResult(extractAuditId: number) {
    this.extractTextService.getExtractAudit(extractAuditId, this.responseId).subscribe((res) => {
      this.processResult(res);
    });
  }

  private processResult(result: ExtractAuditModel) {
    if (result.status === ExtractAuditStatus.IN_PROCESS) {
      // start the timer
      this.startAutoRefresh(result.id);
    } else if (result.status === ExtractAuditStatus.COMPLETE) {
      // fetch the result
      this.extractTextService
        .getExtractOcrResult(result.id, this.responseId, this.processingFile)
        .subscribe((exTextMod) => {
          this.processing.emit(false);

          const filesToSave: FileExtModel[] = [];
          let supportFileProcessed: FileModel = new FileModel();
          if (this.processingFile) {
            const fileExtModel = new FileExtModel();
            fileExtModel.file = this.processingFile;
            fileExtModel.fileModel = new FileModel();
            fileExtModel.fileModel.docType = FileDocType.PROPOSAL;
            filesToSave.push(fileExtModel);
          } else {
            supportFileProcessed.id = this.form.value.fileId;
            supportFileProcessed.name = this.supportFileList.find((sf) => sf.id === supportFileProcessed.id).name;
          }

          this.extractedResponse.emit(
            new ExtractFileModel(
              exTextMod,
              filesToSave,
              ExtractType.OCR,
              supportFileProcessed.name ? supportFileProcessed : null
            )
          );
        });
    } else {
      this.notificationService.failedNotification(
        `An unrecoverable error occurred processing the OCR, please contact support`
      );
      this.processing.emit(false);
    }
  }

  private getSupportFileText(fileId, templateId, file: File): Observable<ExtractAuditModel> {
    return this.extractTextService.extractOcrText(this.responseId, templateId, fileId, file);
  }

  ngOnDestroy(): void {
    this.cancelAutoRefresh();
    this.subs.unsubscribe();
  }

  itemDisplay(item: OcrTemplateModel) {
    return `${item.name} ${!!item.description ? `| ${item.description}` : ''}`;
  }
}
