import { ValidatorFn } from '@angular/forms';
import { AccountContactModel } from '@common/models/account-contact.model';
import { AccountModel } from '@common/models/account.model';
import { TagModel } from '@configuration/admin/tag-list/tag.model';
import { FundingTypeModel } from '@common/models/funding-type.model';
import { FormValidators } from '@form-lib/validators/form.validators';
import { DATA_TYPES, DataDefModel, DataDefOption } from '@lib-resource/data-def.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { StopLossOwnerShipModel } from '@common/models/stop-loss-ownership.model';
import { ParticipantStatModel } from '@quote/model/participantStatModel';
import { RateType } from '@common/models/quote-response.model';
import { QuoteTypeFieldDataModel, QuoteTypeMinModel } from './quote-type.model';

export enum QuoteRequestStatus {
  AWARDED = 'AWARDED',
  BOUND = 'BOUND',
  CLOSED = 'CLOSED',
  DRAFTING = 'DRAFTING',
  SENT_TO_MARKET = 'SENT_TO_MARKET',
  SHORTLIST = 'SHORTLIST',
  EDIT_TERMS = 'EDIT_TERMS' // not a true status that can return from the server, but one used for UI signaling
}

export enum QuoteRequestReason {
  CANCELED_DID_NOT_PURSUE = 'CANCELED_DID_NOT_PURSUE',
  COULD_NOT_FIND_COVERAGE = 'COULD_NOT_FIND_COVERAGE',
  DUPLICATE = 'DUPLICATE',
  GROUP_REMAINED_FULLY_INSURED = 'GROUP_REMAINED_FULLY_INSURED',
  GROUP_TERMINATED = 'GROUP_TERMINATED',
  LOST_STOP_LOSS_KEPT_ADMIN = 'LOST_STOP_LOSS_KEPT_ADMIN',
  PLACED_OUTSIDE_QUOTE_LINQ = 'PLACED_OUTSIDE_QUOTE_LINQ',
  RENEWED_SAME_BROKER = 'RENEWED_SAME_BROKER',
  RENEWED_SAME_CARRIER = 'RENEWED_SAME_CARRIER',
  RENEWED_SAME_TPA = 'RENEWED_SAME_TPA'
}

export enum QuoteRequestBusinessType {
  INCUMBENT_RENEWAL = 'INCUMBENT_RENEWAL',
  NEW_PROSPECT = 'NEW_PROSPECT',
  RENEWAL = 'RENEWAL'
}

export const QuoteRequestBusinessTypeLabel = {
  [QuoteRequestBusinessType.NEW_PROSPECT]: 'New Prospect',
  [QuoteRequestBusinessType.INCUMBENT_RENEWAL]: 'Incumbent Renewal',
  [QuoteRequestBusinessType.RENEWAL]: 'Market Check'
};

export const FundingTypeLabel = {
  [FundingTypeModel.SELF_FUNDED]: 'Self Funded',
  [FundingTypeModel.LEVEL_FUNDED]: 'Level Funded',
  [FundingTypeModel.FULLY_INSURED]: 'Fully Insured',
  [FundingTypeModel.NONE]: 'None'
};

export const canceledDidNotPursue: DataDefOption = {
  value: QuoteRequestReason.CANCELED_DID_NOT_PURSUE,
  label: 'Canceled - Did not pursue'
};
export const couldNotFindCoverage: DataDefOption = {
  value: QuoteRequestReason.COULD_NOT_FIND_COVERAGE,
  label: 'Could not find coverage; requires additional details'
};
export const couldNotFindCoverageView: DataDefOption = {
  value: QuoteRequestReason.COULD_NOT_FIND_COVERAGE,
  label: 'Could not find coverage'
};
export const duplicateClosedReason: DataDefOption = {
  value: QuoteRequestReason.DUPLICATE,
  label: 'Duplicate'
};
export const groupRemainedFullyInsured: DataDefOption = {
  value: QuoteRequestReason.GROUP_REMAINED_FULLY_INSURED,
  label: 'Group remained fully insured'
};
export const groupTerminated: DataDefOption = { value: QuoteRequestReason.GROUP_TERMINATED, label: 'Group terminated' };
export const lostStopLossKeptAdmin: DataDefOption = {
  value: QuoteRequestReason.LOST_STOP_LOSS_KEPT_ADMIN,
  label: 'Lost Stop-Loss, kept administration'
};
export const placedOutsideQuoteLinq: DataDefOption = {
  value: QuoteRequestReason.PLACED_OUTSIDE_QUOTE_LINQ,
  label: 'Placed outside of Quote-LinQ system'
};
export const renewedSameBroker: DataDefOption = {
  value: QuoteRequestReason.RENEWED_SAME_BROKER,
  label: 'Renewed with same Broker'
};
export const renewedSameCarrier: DataDefOption = {
  value: QuoteRequestReason.RENEWED_SAME_CARRIER,
  label: 'Renewed with same Carrier / MGU / UW'
};
export const renewedSameTpa: DataDefOption = {
  value: QuoteRequestReason.RENEWED_SAME_TPA,
  label: 'Renewed with same or new TPA'
};

export const closedReasonOptions: DataDefOption[] = [
  canceledDidNotPursue,
  couldNotFindCoverageView,
  duplicateClosedReason,
  groupRemainedFullyInsured,
  groupTerminated,
  lostStopLossKeptAdmin,
  placedOutsideQuoteLinq,
  renewedSameBroker,
  renewedSameCarrier,
  renewedSameTpa
];
// RX closed reasons
export const rxClosedReasonOptions: DataDefOption[] = [canceledDidNotPursue, couldNotFindCoverage, groupTerminated];
export const rxFormValidation: ValidatorFn = FormValidators.requiredIfSiblingContainsValue('reasonText', 'reason', [
  QuoteRequestReason.COULD_NOT_FIND_COVERAGE
]);
// Broker org type with new prospect
export const brokerNewProspectClosedReasonOptions: DataDefOption[] = [
  renewedSameCarrier,
  groupRemainedFullyInsured,
  placedOutsideQuoteLinq,
  canceledDidNotPursue,
  couldNotFindCoverageView,
  duplicateClosedReason
];
export const brokerNewProspectFormValidation: ValidatorFn = FormValidators.requiredIfSiblingContainsValue(
  'reasonText',
  'reason',
  [QuoteRequestReason.COULD_NOT_FIND_COVERAGE, QuoteRequestReason.PLACED_OUTSIDE_QUOTE_LINQ]
);
// Broker org type with renewal
export const brokerRenewalClosedReasonOptions: DataDefOption[] = [
  groupTerminated,
  placedOutsideQuoteLinq,
  canceledDidNotPursue,
  couldNotFindCoverageView,
  duplicateClosedReason
];
export const brokerRenewalFormValidation: ValidatorFn = FormValidators.requiredIfSiblingContainsValue(
  'reasonText',
  'reason',
  [QuoteRequestReason.COULD_NOT_FIND_COVERAGE, QuoteRequestReason.PLACED_OUTSIDE_QUOTE_LINQ]
);
// TPA org type with new prospect
export const tpaNewProspectClosedReasonOptions: DataDefOption[] = [
  renewedSameBroker,
  renewedSameTpa,
  groupRemainedFullyInsured,
  placedOutsideQuoteLinq,
  canceledDidNotPursue,
  couldNotFindCoverageView,
  duplicateClosedReason
];
export const tpaNewProspectFormValidation: ValidatorFn = FormValidators.requiredIfSiblingContainsValue(
  'reasonText',
  'reason',
  [QuoteRequestReason.COULD_NOT_FIND_COVERAGE, QuoteRequestReason.PLACED_OUTSIDE_QUOTE_LINQ]
);
// TPA org type with renewal
export const tpaRenewalClosedReasonOptions: DataDefOption[] = [
  groupTerminated,
  lostStopLossKeptAdmin,
  placedOutsideQuoteLinq,
  canceledDidNotPursue,
  couldNotFindCoverageView,
  duplicateClosedReason
];
export const tpaRenewalFormValidation: ValidatorFn = FormValidators.requiredIfSiblingContainsValue(
  'reasonText',
  'reason',
  [QuoteRequestReason.COULD_NOT_FIND_COVERAGE, QuoteRequestReason.PLACED_OUTSIDE_QUOTE_LINQ]
);

export const rfpClosedReasonDefWithoutLabel: DataDefModel = new DataDefModel({
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true }
});

export const rfpClosedReasonDef: DataDefModel = new DataDefModel({
  ...rfpClosedReasonDefWithoutLabel,
  label: 'Reason'
});

export const rfpAllCloseReasonDef: DataDefModel = new DataDefModel({
  ...rfpClosedReasonDef,
  options: closedReasonOptions
});

export const rfpStageOptions: LabelValue[] = [
  {
    label: 'Drafting',
    value: QuoteRequestStatus.DRAFTING
  },
  {
    label: 'Sent to Market',
    value: QuoteRequestStatus.SENT_TO_MARKET
  },
  {
    label: 'Shortlist',
    value: QuoteRequestStatus.SHORTLIST
  },
  {
    label: 'Awarded',
    value: QuoteRequestStatus.AWARDED
  },
  {
    label: 'Closed',
    value: QuoteRequestStatus.CLOSED
  },
  {
    label: 'Bound',
    value: QuoteRequestStatus.BOUND
  }
];

export const incumbentRenewalStageOptions: LabelValue[] = [
  {
    label: 'Drafting',
    value: QuoteRequestStatus.DRAFTING
  },
  {
    label: 'Renewal Requested',
    value: QuoteRequestStatus.SENT_TO_MARKET
  },
  {
    label: 'Closed',
    value: QuoteRequestStatus.CLOSED
  },
  {
    label: 'Bound',
    value: QuoteRequestStatus.BOUND
  }
];

export class QuoteRequestModel {
  id?: number;
  name?: string;
  description?: string;
  status?: QuoteRequestStatus;
  quoteType?: QuoteTypeMinModel;
  account?: AccountModel;
  participantStat?: ParticipantStatModel;
  accountContact?: AccountContactModel;
  baselineVersionId?: number;
  baselineOptionId?: number;
  orgIds?: Array<number>;
  dueDate?: string;
  effectiveDate?: string;
  quoteData?: any;
  receivedDate?: any;
  brokerDueDate?: string;
  responseCnt?: number;
  renewal?: boolean;
  renewalQuoteRequestId?: number;
  tags?: TagModel[];
  createdDate?: Date;
  verifierName?: string;
  verifierTitle?: string;
  reason?: QuoteRequestReason;
  reasonText?: string;
  enrollmentTier?: RateType;
  enrollmentEmployeeOnly?: number;
  enrollmentEmployeeSpouse?: number;
  enrollmentEmployeeChildren?: number;
  enrollmentEmployeeDependent?: number;
  enrollmentFamily?: number;
  enrollmentComposite?: number;
  enrollmentTotalEligibleEmployees?: number;
  businessType?: QuoteRequestBusinessType;
  renewalPromotionDate?: string;
  stopLossOwnership?: StopLossOwnerShipModel;
  quoteTypeFieldData?: QuoteTypeFieldDataModel[];
  fundingType?: FundingTypeModel;
  intake?: boolean;
}

export class CopySupportFileRequestModel {
  copyFromQuoteRequestId?: number;
  fileIdsToCopy?: string[];
}

export class StopLossSoldSheetFinalTermsModel {
  totalEnrollment?: number;
  specContractType?: string;
  specDeductible?: number;
  aggSpec?: number;
  premiumAmount?: number;
  aggContractType?: string;
  aggDeductible?: number;
  aggEstAnnualPremium?: number;
  annualFixedCosts?: number;
  verifierName?: string;
  verifierTitle?: string;
  planType?: string;
}

export class QuoteRequestReasonChangeModel {
  reason: QuoteRequestReason;
  reasonText: string;
  quoteResponseOrgId: number;
  underWriter: string;
}

export function stripLFSuffixFromQuoteData(quoteData: any): any {
  let modifiedQuoteData = {};
  for (let key in quoteData) {
    if (Object.prototype.hasOwnProperty.call(quoteData, key)) {
      modifiedQuoteData[key.endsWith('LF') ? key.slice(0, -2) : key] = quoteData[key];
    }
  }
  return modifiedQuoteData;
}

export function appendLFSuffixToQuoteData(quoteData: any): any {
  let modifiedQuoteData = {};
  for (let key in quoteData) {
    if (Object.prototype.hasOwnProperty.call(quoteData, key)) {
      modifiedQuoteData[key.startsWith('current') ? key : key + 'LF'] = quoteData[key];
    }
  }
  return modifiedQuoteData;
}
